import { useEffect, useState } from "react";
import { Editor, EditorContext, useEditor } from "../../data/editor";
import { BaseObject, EditableFrame } from "../../data/model";
import { PropButton, SidebarSection, SidebarSegmentedControl, SidebarTextArea } from "./controls";
import OpenAI from "openai";
import { renderToString } from "react-dom/server";
import { DocumentRenderContext, ObjectView } from "../objectViews/DocumentView";
import pretty from 'pretty';
import { OPENROUTER_KEY } from "../../data/secrets";

interface MakeRealProps {
    object: EditableFrame;
}

export default function MakeReal(props: MakeRealProps) {
    const object = props.object;
    const editor = useEditor();
    const [prompt, setPrompt] = useState("");
    const [generating, setGenerating] = useState(false);

    useEffect(() => {
        setPrompt(object.behaviorDescription || "");
    }, [object.id]);

    function generate() {
        if (generating) {
            return;
        }
        setGenerating(true);
        generateCode(prompt, editor, object.id).then(code => {
            const objectId = object.id;
            editor.modifyUndoable(state => {
                const oldPrompt = object.behaviorDescription;
                const oldCode = object.code;
                return {
                    do: state => {
                        const obj = (state.document.objects[objectId] as EditableFrame);
                        obj.behaviorDescription = prompt;
                        obj.code = TEMPLATE.replace(TEMPLATE_TOKEN_TO_REPLACE, code);
                    },
                    undo: state => {
                        const obj = (state.document.objects[objectId] as EditableFrame);
                        obj.behaviorDescription = oldPrompt;
                        obj.code = oldCode;
                    }
                }
            });
            setGenerating(false);
        }).catch(e => {
            console.error(e);
            setGenerating(false);
        });
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        e.stopPropagation();
    };

    function setDisplayAsCode(code: boolean) {
        editor.modifyUndoable(state => {
            const oldVal = object.displayAsCode;
            return {
                do: state => {
                    const obj = (state.document.objects[object.id] as EditableFrame);
                    obj.displayAsCode = code;
                },
                undo: state => {
                    const obj = (state.document.objects[object.id] as EditableFrame);
                    obj.displayAsCode = oldVal;
                }
            }
        });
    }

    return (
        <SidebarSection data-wants-spinner-cursor={generating}>
            <h6>Make Real</h6>
            <label>Optional description of functionality:</label>
            <SidebarTextArea value={prompt} onChange={e => setPrompt(e.target.value)} onKeyDown={onKeyDown} />
            <PropButton onClick={generate} disabled={generating} label="Generate" />

            <SidebarSegmentedControl<boolean>
                options={[false, true]}
                selection={object.displayAsCode ?? false}
                viewForOption={option => option ? 'Code' : 'Design'}
                onChange={setDisplayAsCode} />
        </SidebarSection>
    );
}


async function generateCode(prompt: string, editor: Editor, objectId: string): Promise<string> {
    const string = renderObjectToString(objectId, editor);

    console.log('INPUT HTML:\n', string);

    // const client = new OpenAI({apiKey: OPENAI_KEY, dangerouslyAllowBrowser: true});
    const client = new OpenAI({
        apiKey: OPENROUTER_KEY(), 
        dangerouslyAllowBrowser: true, 
        baseURL: 'https://openrouter.ai/api/v1'
    });
    const sys = `
    As an expert web developer, React, JS and HTML expert, I'd like you to write standalone code for me that will run in an iframe.
    Here's how your code will run:
    \`
    ${TEMPLATE}
    \`

    Here's an example of a simple React component that we might slot in here:
    \`
    function Hello() { return <h1>Im React</h1>; }

    ReactDOM.createRoot(document.getElementById("root-box")).render(
        <Hello />
    );
    \`

    Guidelines:
    - You should not import any external libraries or use any external resources. Your code should be standalone and self-contained.
    - Write all the code necessary; do not omit code. It needs to be valid and runnable.
    - Fill the viewport unless there's a good reason not to.

    Below, I'll give you the user's prompt. Then, you'll write the code.
    `;

    const taskPrompt = `
    I'd like you to code an interactive webpage.
    You'll take my static HTML and "make it real" by transforming it into a functional, high-quality React app, without changing its visual appearance or layout.

    Pay attention to clues in the HTML, labels, and data-comment attrs to infer functionality.
    Keep my layout and styling the same, but make it interactive. Copy the styling and layout; only modify it if asked or to support interactivity. Otherwise copy all styling attributes as-is.
    You may invent new UI stylings if necessary to handle edge cases, BUT you should not modify the layout or appearance of my HTML.

    I want it to look the same way it looks now, but interactive.
    You may replace plain divs with richer components (e.g. textareas, canvas) if you think that's the intent.
    If the app needs data persistence, use local storage.
    Begin your code with a comment explaining what this code will DO (based on user's description and your assumptions from the structure) and how you will do it.

    Here is the static HTML:
    \`\`\`
    ${string}
    \`\`\`

    And here's some info on the desired functionality. If it's missing, infer it from the HTML structure and text:
    \`\`\`
    ${prompt}
    \`\`\`

    Respond by writing your code within <written-code> tags, like this: <written-code>...</written-code>.
    `;

    // console.log('prompt', taskPrompt);

    const params: OpenAI.Chat.ChatCompletionCreateParams = {
        messages: [
            { role: 'system', content: sys },
            { role: 'user', content: taskPrompt }
        ],
        model: 'openai/gpt-4o',
        // response_format: {type: 'json_object'}
      };
      const chatCompletion: OpenAI.Chat.ChatCompletion = await client.chat.completions.create(params);
      const response = chatCompletion.choices[0].message.content as string;
      console.log('WRITTEN CODE:\n', response);
      const parts = response.split('<written-code>');
      if (parts.length !== 2) {
        throw new Error('No code response from OpenAI');
      }
      const code = parts[1].split('</written-code>')[0];
    //   console.log(code);
      return code;
    //   if (!json) {
    //         throw new Error('No JSON response from OpenAI');
    //   }

}

const TEMPLATE = `
<html>
<head>
    <script src="https://unpkg.com/react@18/umd/react.development.js"></script>
    <script src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"></script>
    <script src="https://unpkg.com/@babel/standalone@7.19.3/babel.js"></script>
    <style>
    body, html {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #root-box {
        height: 100%;
        width: 100%;
    }
    </style>
</head>
<body>
    <div id="root-box"></div>
    <script data-plugins="transform-modules-umd" type="text/babel" data-presets="react" data-type="module">
    [YOUR SCRIPT HERE]
    </script>
</body>
</html>
`

const TEMPLATE_TOKEN_TO_REPLACE = '[YOUR SCRIPT HERE]';

function renderObjectToString(objectId: string, editor: Editor): string {
    const str = renderToString((
        <DocumentRenderContext.Provider value={{ renderingToString: true }}>
            <EditorContext.Provider value={editor}>
                <ObjectView id={objectId} renderToStringRoot={true} />
            </EditorContext.Provider>
        </DocumentRenderContext.Provider>
    ));
    return pretty(str);
}
