import React, { useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import { DocumentView } from './ui/objectViews/DocumentView';
import EditorView from './ui/editorView';
import { Editor, EditorContext } from './data/editor';
import { useParsedRoute } from './routing';
import LoginGated from './webapp/loginGated';
import { APIClientProvider, useAPIClient } from './data/apiClient/apiClientHooks';
import HomeScreen from './webapp/HomeScreen';

// const globalEditor = new Editor();

function App() {
  return (
    <APIClientProvider>
      <_App />
    </APIClientProvider>
  )
}

function _App() {
  const parsedRoute = useParsedRoute();

  if (parsedRoute === null) {
    return <div>Invalid URL</div>;
  }

  switch (parsedRoute.kind) {
    case 'home':
      return (
        <LoginGated>
          <HomeScreen />
        </LoginGated>
      )
    case 'editDoc':
      return (
        <LoginGated>
          <EditorApp docId={parsedRoute.docId} key={parsedRoute.docId} />
        </LoginGated>
      )
    case 'viewDocPage':
      return <RenderAppPage docId={parsedRoute.docId} pageName={parsedRoute.pageName} key={parsedRoute.docId} />;
  }
  return <h1>404</h1>
}

function EditorApp({docId}: {docId: string}) {
  const apiClient = useAPIClient();
  const editor = useRef<Editor | null>();
  if (!editor.current || editor.current.docId !== docId) {
    editor.current = new Editor(apiClient, docId);
  }
  return (
    <div className="EditorApp">
      <EditorContext.Provider value={editor.current}>
        <EditorView />
      </EditorContext.Provider>
    </div>
  );
}

function RenderAppPage({docId, pageName}: {docId: string, pageName: string}) {
  // TODO
  return (
    <h1>Would render {pageName} of {docId}</h1>
  )
}

export default App; 
