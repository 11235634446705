import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import InfiniteCanvas from "./infiniteCanvas";
import { DocumentView } from "./objectViews/DocumentView";
import { InfiniteCanvasPosition, Rect, ZeroRect, boundingRectForAllSelectors } from "../data/geo";
import { EditorState, useEditor, useEditorDisplayState, useEditorState } from "../data/editor";
import { handleKeys } from "./handleKeys";
import { useMouseHandlers } from "./clickHandlers";
import SelectionFrame from "./selectionFrame";
import CanvasMarksView from "./canvasMarks";
import Sidebar from "./sidebar/sidebar";
import styled from "styled-components";

const EditorDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const CanvasWrapper = styled.div`
    flex: 1;
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    background-color: #DFDCD5;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    &:focus {
        outline: none;
    }
`;

export default function EditorView() {
    // const doc = useEditorState(state => editorStateModifiedForDisplay(state).document);
    const selection = useEditorDisplayState(state => state.selectedObjects);
    const editor = useEditor();
    const penMode = useEditorState(state => state.penMode);
    const infiniteCanvasPosition = useEditorDisplayState(state => state.canvasPos);
    const setInfiniteCanvasPosition = useCallback((pos: InfiniteCanvasPosition) => editor.modify(state => state.canvasPos = pos), []);

    const canvasWrapperRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<HTMLDivElement>(null);
    
    const {mouseDown, mouseMove, mouseUp, mouseLeave} = useMouseHandlers(editor, canvasWrapperRef);

    useLayoutEffect(() => {
        editor.viewportRef = canvasWrapperRef;
        return () => {
            if (editor.viewportRef === canvasWrapperRef) {
                editor.viewportRef = undefined;
            }
        };
    }, [editor, canvasWrapperRef]);

    function handleKeyDown(e: KeyboardEvent) {
        const handled = handleKeys(editor, e);
        if (handled) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        // Register document key handler and cancel it when the component unmounts
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    let cursor = useEditorState(state => cursorForState(state));

    return (
        <EditorDiv className="editor" ref={editorRef}>
            <CanvasWrapper className="canvasWrapper" onMouseDown={mouseDown} onMouseMove={mouseMove} onMouseUp={mouseUp} onMouseLeave={mouseLeave} ref={canvasWrapperRef}>
                <InfiniteCanvas position={infiniteCanvasPosition} onPositionChange={setInfiniteCanvasPosition} attachSwipeListenerToRef={canvasWrapperRef}>
                    <DocumentView />
                </InfiniteCanvas>
                { cursor ? <CursorOverlay cursor={cursor} /> : null }
                {
                    selection.size > 0 ? <SelectionFrame objectIds={Array.from(selection)} wrapperRef={canvasWrapperRef} infiniteCanvasPosition={infiniteCanvasPosition} /> : null
                }
                <CanvasMarksView wrapperRef={canvasWrapperRef} infiniteCanvasPosition={infiniteCanvasPosition} />
            </CanvasWrapper>
            <Sidebar canvasWrapper={canvasWrapperRef} />
        </EditorDiv>
    )
}

const CursorOverlayDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

function CursorOverlay({cursor}: {cursor: React.CSSProperties['cursor']}) {
    return <CursorOverlayDiv className="canvasOverlay" style={{cursor}} />
}

function cursorForState(state: EditorState): React.CSSProperties['cursor'] | undefined {
    if (state.movesInProgress.length > 0) {
        return 'grabbing'
    }
    if (state.penMode) {
        return 'crosshair';
    }
    return undefined;
}
