import { Point } from "../data/geo";

interface ResizeHandleViewProps {
    handle: ResizeHandle
}

export function ResizeHandleView({handle}: ResizeHandleViewProps) {
    const size = 10;
    const css: React.CSSProperties = {
        position: 'absolute',
        marginLeft: -size/2,
        marginTop: -size/2,
        width: size,
        height: size,
        backgroundColor: '#00b9ff',
        // boxShadow: '0px 1px 2px rgba(0,0,0,0.1)',
        borderRadius: 2,
        left: `${handle.unitPos.x * 100}%`,
        top: `${handle.unitPos.y * 100}%`,
        pointerEvents: 'auto',
        cursor: handle.cursor
    };
    return <div data-resize-handle-id={handle.id} style={css} />;
}

export interface ResizeHandle {
    id: string
    cursor: React.CSSProperties['cursor'];
    unitPos: Point;
    x?: 'leading' | 'trailing';
    y?: 'leading' | 'trailing';
}

export const RESIZE_HANDLES: ResizeHandle[] = (() => {
    const handles: ResizeHandle[] = [
        {
            id: 'w',
            x: 'leading',
            cursor: 'ew-resize',
            unitPos: {x: 0, y: 0.5}
        },
        {
            id: 'e',
            x: 'trailing',
            cursor: 'ew-resize',
            unitPos: {x: 1, y: 0.5}
        },
        {
            id: 'n',
            y: 'leading',
            cursor: 'ns-resize',
            unitPos: {x: 0.5, y: 0}
        },
        {
            id: 's',
            y: 'trailing',
            cursor: 'ns-resize',
            unitPos: {x: 0.5, y: 1}
        },
        {
            id: 'nw',
            x: 'leading',
            y: 'leading',
            cursor: 'nwse-resize',
            unitPos: {x: 0, y: 0}
        },
        {
            id: 'ne',
            x: 'trailing',
            y: 'leading',
            cursor: 'nesw-resize',
            unitPos: {x: 1, y: 0}
        },
        {
            id: 'sw',
            x: 'leading',
            y: 'trailing',
            cursor: 'nesw-resize',
            unitPos: {x: 0, y: 1}
        },
        {
            id: 'se',
            x: 'trailing',
            y: 'trailing',
            cursor: 'nwse-resize',
            unitPos: {x: 1, y: 1}
        },
    ]
    return handles;
})()

