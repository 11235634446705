import React, { useState, useEffect } from 'react';
import { User } from '@supabase/supabase-js';
import { SHARED_SUPABASE_CLIENT } from '../data/apiClient/supabaseClient';

interface LoginGatedProps {
  children: React.ReactNode;
}

function LoginGated({ children }: LoginGatedProps) {
    const supabase = SHARED_SUPABASE_CLIENT;
  const [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    // Check initial auth state
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_, session) => {
      setUser(session?.user ?? null);
    });

    // Cleanup subscription
    return () => {
      subscription.unsubscribe();
    };
  }, [supabase.auth]);

  const handleGoogleSignIn = async () => {
    const curUrl = new URL(window.location.href);
    curUrl.pathname = '/'
    await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: { redirectTo: curUrl.href },
    });
  };

  if (user) {
    return <>{children}</>;
    // return (
    //   <div>
    //     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
    //       <span>Welcome, {user.user_metadata.full_name || user.email}!</span>
    //       <button onClick={handleSignOut}>Sign Out</button>
    //     </div>
    //     {children}
    //   </div>
    // );
  }

  if (user === undefined) {
    // Still loading
    return <div>Loading...</div>;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div style={{ backgroundColor: '#f0f0f0', padding: '2rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <h2 style={{ marginBottom: '1rem' }}>Please sign in</h2>
        <button onClick={handleGoogleSignIn}>Sign in with Google</button>
      </div>
    </div>
  );
};

export default LoginGated;
