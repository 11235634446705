import { useCallback, useEffect, useState } from "react";
import { BaseObject } from "../../data/model"
import { SidebarSection, SidebarTextArea } from "./controls";
import { ifAllEqual } from "./style";
import { useEditor } from "../../data/editor";

interface AdvancedSidebarSectionProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    objects: BaseObject[]
}

export default function AdvancedSidebarSection(props: AdvancedSidebarSectionProps) {
    const {objects} = props;
    const selectionId = uniqueIdForSelection(objects);

    // TODO: Support show/hide
    const [css, setCSS] = useState('');
    const editor = useEditor();

    useEffect(() => {
        const sharedStyles = ifAllEqual(objects.map(o => o.userCSS));
        setCSS(convertStylesToString(sharedStyles || {}));
    }, [selectionId]);

    const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCSS(e.target.value);
        const newStyles = convertStringToStyles(e.target.value);
        editor.modifyUndoable(state => {
            const oldStyles: [string, React.CSSProperties | undefined][] = objects.map(o => [o.id, o.userCSS]);
            return {
                do: state => {
                    for (const obj of objects) {
                        const objFromState = state.document.objects[obj.id];
                        if (objFromState) {
                            objFromState.userCSS = newStyles;
                        }
                    }
                    return state;
                }, 
                undo: state => {
                    for (const [id, styles] of oldStyles) {
                        const objFromState = state.document.objects[id];
                        if (objFromState) {
                            objFromState.userCSS = styles;
                        }
                    }
                    return state;
                },
                gestureId: selectionId,
            };
        });
    }, [selectionId]);

    const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Escape') {
            e.currentTarget.blur();
        }
    }, []);

    // TODO: Reflect visually if the selection has mixed styles

    if (objects.length === 0) {
        return null;
    }

    return (
        <SidebarSection>
            <label>Styles</label>
            <SidebarTextArea value={css} onChange={onChange} onKeyUp={onKeyUp} />
        </SidebarSection>
    );
}

function uniqueIdForSelection(objects: BaseObject[]): string {
    const objectIds = objects.map(o => o.id);
    objectIds.sort();
    return objectIds.join(', ');
}

function convertStylesToString(styles: React.CSSProperties) {
    return Object.entries(styles).map(([key, value]) => `${camelcaseToHyphen(key)}: ${value};`).join('\n');
}

function convertStringToStyles(str: string): React.CSSProperties {
    const styles: any = {};
    // HACK: This is all crap written by copilot
    // TODO: improve
    const withoutComments = str.replace(/\/\*[\s\S]*?\*\/|([^:]|^)\/\/.*$/gm, '$1');
    const lines = withoutComments.split(';').map(l => l.trim());
    // Drop last line (since it has no semicolon)
    lines.pop();

    for (const line of lines) {
        let [key, value] = line.split(':');
        if (key && value) {
            value = value.trim();
            value = removeQuotesFromStringLiteral(value);
            styles[hyphenToCamelcase(key.trim())] = value;
        }
    }
    return styles;
}

function removeQuotesFromStringLiteral(str: string): string {
    // TODO: Support escaping
    for (const quote of ['"', "'"]) {
        if (str.startsWith(quote) && str.endsWith(quote)) {
            return str.slice(1, -1);
        }
    }
    return str;
}

function camelcaseToHyphen(str: string): string {
    return str.replace(/[A-Z]/g, m => '-' + m.toLowerCase());
}

function hyphenToCamelcase(str: string): string {
    return str.replace(/-([a-z])/g, g => g[1].toUpperCase());
}

export {};
