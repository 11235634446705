import { useLayoutEffect, useRef, useState } from "react";
import { EditorState, useEditorState } from "../data/editor";
import { InfiniteCanvasPosition, Rect, ZeroRect, boundingRectForAllSelectors } from "../data/geo";

type CanvasMark = {
    key: string
    kind: 'objectBox',
    color: string,
    id: string
} | {
    key: string
    kind: 'snapGuide',
    axis: 'x' | 'y',
    value: number // in viewport coords
};

function computeMarks(state: EditorState): CanvasMark[] {
    const marks: CanvasMark[] = [];
    if (state.movesInProgress) {
        const highlightObjectIds = new Set<string>(state.movesInProgress.flatMap(move => {
            if (move.location.parent) {
                return [move.location.parent]
            }
            return [];
        }))
        highlightObjectIds.forEach(id => {
            marks.push({
                key: `move-${id}`,
                kind: 'objectBox', 
                color: 'blue', 
                id 
            })
        });
        for (const move of state.movesInProgress) {
            if (move.snapRuleX !== undefined) {
                marks.push({
                    key: `snap-x-${move.snapRuleX}`,
                    kind: 'snapGuide',
                    axis: 'x',
                    value: move.snapRuleX
                });
            }
            if (move.snapRuleY !== undefined) {
                marks.push({
                    key: `snap-y-${move.snapRuleY}`,
                    kind: 'snapGuide',
                    axis: 'y',
                    value: move.snapRuleY
                });
            }
        }
        for (const resize of state.resizesInProgress) {
            if (resize.snapRuleX !== undefined) {
                marks.push({
                    key: `snap-x-${resize.snapRuleX}`,
                    kind: 'snapGuide',
                    axis: 'x',
                    value: resize.snapRuleX
                });
            }
            if (resize.snapRuleY !== undefined) {
                marks.push({
                    key: `snap-y-${resize.snapRuleY}`,
                    kind: 'snapGuide',
                    axis: 'y',
                    value: resize.snapRuleY
                });
            }
        }
    }
    return marks;
}

interface CanvasMarksViewProps {
    wrapperRef: React.RefObject<HTMLDivElement>;
    infiniteCanvasPosition: InfiniteCanvasPosition; // We pass this to force redraw when the canvas position changes
}

export default function CanvasMarksView(props: CanvasMarksViewProps) {
    const marks = useEditorState(computeMarks);
    return (
        <div className="canvasMarks canvasOverlay" style={{pointerEvents: 'none'}}>
            {marks.map(mark => {
                if (mark.kind === 'objectBox') {
                    return <ObjectBox key={mark.key} id={mark.id} wrapperRef={props.wrapperRef} infiniteCanvasPosition={props.infiniteCanvasPosition} color={mark.color} />;
                }
                if (mark.kind === 'snapGuide') {
                    return <SnapGuideMark key={mark.key} axis={mark.axis} value={mark.value} />;
                }
                return null;
            })}
        </div>
    )
}

interface ObjectBoxProps {
    id: string;
    wrapperRef: React.RefObject<HTMLDivElement>;
    infiniteCanvasPosition: InfiniteCanvasPosition; // We pass this to force redraw when the canvas position changes
    color: string;
}

// Use a layout effect to search the wrapper DOM for the selected object and set this view's position to it
function ObjectBox(props: ObjectBoxProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [rect, setRect] = useState<Rect>(ZeroRect);

    useLayoutEffect(() => {
        const wrapper = props.wrapperRef.current;
        if (wrapper) {
            const wrapperRect = wrapper.getBoundingClientRect();
            const boundingRect = boundingRectForAllSelectors(wrapper, [`[data-object-id="${props.id}"]`], -wrapperRect.left, -wrapperRect.top);
            setRect(boundingRect || ZeroRect);
        }
    }, [props.id, props.wrapperRef, props.infiniteCanvasPosition]);

    return (
        <div ref={ref} style={{ border: `1px solid ${props.color}`, boxSizing: 'border-box', position: 'absolute', left: rect.x, top: rect.y, width: rect.width, height: rect.height }}>
        </div>
    );
}

interface SnapGuideMarkProps {
    axis: 'x' | 'y';
    value: number;
}

function SnapGuideMark(props: SnapGuideMarkProps) {
    if (props.axis === 'x') {
        return <div style={{position: 'absolute', left: props.value, top: 0, width: 1, height: '100%', backgroundColor: 'red'}} />
    }
    return <div style={{position: 'absolute', left: 0, top: props.value, width: '100%', height: 1, backgroundColor: 'red'}} />
}
