import OpenAI from "openai";
import { ObjectParam } from "../model";
import { OPENROUTER_KEY } from "../secrets";

export const DEFAULT_HTML_EMBED_CHECKERBOARD = `
<html>
  <head>
    <style>
      body, html {
        height: 100%;
        margin: 0;
      }
      .checkerboard {
        width: 100%;
        height: 100%;
        background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><rect width="20" height="20" fill="black"/><rect y="20" width="20" height="20" fill="white"/><rect x="20" width="20" height="20" fill="white"/><rect x="20" y="20" width="20" height="20" fill="black"/></svg>');
      }
    </style>
  </head>
  <body>
    <div class="checkerboard"></div>
  </body>
</html>
`;

export const DEFAULT_HTML_EMBED_TESTING = `
<html>
  <head>
    <script src="https://unpkg.com/react@18/umd/react.development.js"></script>
    <script src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"></script>
    <script src="https://unpkg.com/@babel/standalone@7.19.3/babel.js"></script>
  </head>
  <body>
    <h1>Im HTML</h1>
    <div id="root-box"></div>
    <script data-plugins="transform-modules-umd" type="text/babel" data-presets="react" data-type="module">
        function Hello() {
            const [params, setParams] = React.useState({});
            React.useEffect(() => {
                window.addEventListener('message', event => {
                    if (event.data.kind === 'paramsChanged') {
                        setParams(old => ({...old, ...event.data.params}));
                    }
                });
                window.parent.postMessage({ kind: 'requestInitialParams' }, '*');
            }, []);
            return <h1>Params: {JSON.stringify(params)}</h1>;
        }

      ReactDOM.createRoot(document.getElementById("root-box")).render(
        <Hello />
      );
    </script>
  </body>
</html>
`;

export const DEFAULT_HTML_EMBED = DEFAULT_HTML_EMBED_CHECKERBOARD;

export async function generateHTMLEmbedCode(prompt: string, params: ObjectParam[]): Promise<string> {
    const sysPromptLines: string[] = [];
    sysPromptLines.push('The user will give you a description and you should generate expertly-written but pragmatic HTML code that will run in an iframe.');
    
    sysPromptLines.push('# Runtime environment')
    sysPromptLines.push("Your code will run directly in the browser within an iframe, which means you need to use a service lik unpkg if you want to important any dependencies.");
    sysPromptLines.push("Here's an example of how you might write React this way:");
    sysPromptLines.push(`
        <html>
        <head>
            <script src="https://unpkg.com/react@18/umd/react.development.js"></script>
            <script src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"></script>
            <script src="https://unpkg.com/@babel/standalone@7.19.3/babel.js"></script>
        </head>
        <body>
            <div id="app-root"></div>
            <script data-plugins="transform-modules-umd" type="text/babel" data-presets="react" data-type="module">
            function App() {
                return <h1>Hello from React</h1>;
            }
            ReactDOM.createRoot(document.getElementById("app-root")).render(
                <App />
            );
            </script>
        </body>
        </html>
    `);
    sysPromptLines.push('# Other relevant importable libs');
    sysPromptLines.push('Import these if you need them:');
    sysPromptLines.push('**ThreeJS**: https://unpkg.com/three@0.77.0/three.min.js');
    sysPromptLines.push(`
    **OpenAI API**: https://unpkg.com/openai@4.38.3/index.mjs
    <example>
        const client = new OpenAI({
            apiKey: 'YOUR_KEY', 
            dangerouslyAllowBrowser: true, 
        });
        const chatParams = {
        messages: [
            { role: 'user', content: 'My prompt...' }
        ],
        model: 'gpt-4o-mini'
      };
      const response = await client.chat.completions.create(chatParams).choices[0].message.content;
      </example>
    `);
    
    sysPromptLines.push('# Guidelines');
    sysPromptLines.push('- Your code should be standalone and self-contained.');
    sysPromptLines.push('- Your app should be response and fill the viewport');
    
    const nonSignalParams = params.filter(p => p.kind.kind !== 'signal');
    const signalParams = params.filter(p => p.kind.kind === 'signal');

    if (nonSignalParams.length > 0) {
        sysPromptLines.push(`
        # Parameters
        External parameters will be passed to your iframe using window.postMessage.
        You should post a 'requestInitialParams' message to the parent window to get the initial parameters,
        then continue to listen and react to changes. For example:

        <script data-plugins="transform-modules-umd" type="text/babel" data-presets="react" data-type="module">
        function App() {
            const [params, setParams] = React.useState({});
            React.useEffect(() => {
                window.addEventListener('message', event => {
                    if (event.data.kind === 'paramsChanged') {
                        setParams(old => ({...old, ...event.data.params}));
                    }
                });
                window.parent.postMessage({ kind: 'requestInitialParams' }, '*');
            }, []);
            // Use your params below
            return <h1>Params: {JSON.stringify(params)}</h1>;
        }
        ReactDOM.createRoot(document.getElementById("app-root")).render(
            <App />
        );
        </script>

        `)
        
    }

    if (signalParams.length > 0) {
        // TODO
    }
    
    const userPromptLines = ["# User Prompt"]

    if (prompt.length > 0) {
        userPromptLines.push(`> ${prompt}`);
    }

    if (nonSignalParams.length > 0) {
        userPromptLines.push('# Parameters');
        userPromptLines.push('Here are the params that may be passed, and their types. Consider this the type of the `event.data.params` field on the `paramsChanged` messages.');
        userPromptLines.push('`\n{');
        for (const param of nonSignalParams) {
            userPromptLines.push(`  ${typescriptDefForParam(param)}`);
        }
        userPromptLines.push('}\n`');
    }
    if (signalParams.length > 0) {
        userPromptLines.push('# Signals');
        userPromptLines.push('Here are the signals that you may call, and their types.');
        userPromptLines.push('`\n{');
        for (const param of signalParams) {
            userPromptLines.push(`  ${typescriptDefForParam(param)}`);
        }
        userPromptLines.push('}\n`');
    }

    userPromptLines.push('Now, write the code that will run in the iframe. Do not write any other commentary besides your code! Write your code within <html> tags.');

    const chatParams: OpenAI.Chat.ChatCompletionCreateParams = {
        messages: [
            { role: 'system', content: sysPromptLines.join('\n') },
            { role: 'user', content: userPromptLines.join('\n') }
        ],
        model: 'anthropic/claude-3.5-sonnet',
        temperature: 0
        // response_format: {type: 'json_object'}
      };
      console.log(chatParams);
      const client = new OpenAI({
        apiKey: OPENROUTER_KEY(), 
        dangerouslyAllowBrowser: true, 
        baseURL: 'https://openrouter.ai/api/v1'
    });
      const chatCompletion: OpenAI.Chat.ChatCompletion = await client.chat.completions.create(chatParams);
      let code = chatCompletion.choices[0].message.content;
      console.log(code);
      if (!code) {
            throw new Error('No JSON response from OpenRouter');
      }
      const htmlIndex = code.indexOf('<html>');
      if (htmlIndex !== -1) {
            code = code.slice(htmlIndex);
      }
      const closingTag = '</html>';
      const closingTagIndex = code.indexOf(closingTag);
      if (closingTagIndex !== -1) {
        code = code.slice(0, closingTagIndex + closingTag.length);
      }
      return code;
}

function typescriptDefForParam(param: ObjectParam): string {
    switch (param.kind.kind) {
        case 'string':
            return `'${param.name}': string`;
        case 'number':
            return `'${param.name}': number`;
        case 'boolean':
            return `'${param.name}': boolean`;
        case 'color':
            return `'${param.name}': {r: number, g: number, b: number, a: number} // 0..255 for r,g,b; 0..1 for a`;
        case 'dict':
            return `any` // TODO
        case 'signal': {
            if (!param.kind.signalValue) {
                return `${param.name}: (any) => void`; // oops!
            }
            switch (param.kind.signalValue.kind) {
                case 'string':
                    return `'${param.name}': (string) => void`;
                case 'number':
                    return `'${param.name}': (number) => void`;
                case 'boolean':
                    return `'${param.name}': (boolean) => void`;
                case 'color':
                    return `'${param.name}': ({r: number, g: number, b: number, a: number}) => void`;
                    default: return `${param.name}: any`; // oops!
            }
        }
    }
}
