import { Document, EditableFrame, EditableText, paddingAll } from "./model";
import { v4 as uuidv4 } from 'uuid';
import { addObject } from "./operations";

// Create 
export function createDefaultDoc(id: string | undefined = undefined): Document {
    const doc: Document = {
        id: id || 'test',
        objects: {},
        rootIds: [],
        name: 'Untitled Document',
    };
    // Create a root frame:
    const rootFrame: EditableFrame = {
        id: 'rootFrame1',
        type: 'editableFrame',
        children: [],
        name: "Frame 1",
        isArtboard: true,
        layout: {
            // xPosition: { fixed: 'pixels', leading: 0 },
            // yPosition: { fixed: 'pixels', leading: 0 },
            // xSize: { fixed: 'pixels', value: 400 },
            // ySize: { fixed: 'pixels', value: 600 }
            position: {
                kind: "absolute",
                x: { value: 0, anchor: 'leading', unit: 'pixels' },
                y: { value: 0, anchor: 'leading', unit: 'pixels' }
            },
            xSize: { kind: 'fixed', value: 400, unit: 'pixels' },
            ySize: { kind: 'fixed', value: 600, unit: 'pixels' },
        },
        stylingProps: {
            clip: true,
            background: {
                color: { r: 255, g: 255, b: 255, a: 1 }
            }
        }
    };
    // doc.objects[rootFrame.id] = rootFrame;
    // doc.rootIds.push(rootFrame.id);
    addObject(doc, rootFrame, { parent: null, index: -1 });

    // Create gray rect that fills 50% of the viewport and starts at 25,25%
    const grayRect: EditableFrame = {
        id: 'grayRect',
        type: 'editableFrame',
        children: [],
        layout: {
            // xSize: { fixed: 'percent', value: 50 },
            // ySize: { fixed: 'percent', value: 50 },
            // xPosition: { fixed: 'percent', leading: 25 },
            // yPosition: { fixed: 'percent', leading: 25 }
            xSize: { kind: 'fixed', value: 50, unit: 'percent' },
            ySize: { kind: 'fixed', value: 50, unit: 'percent' },
            position: {
                kind: 'absolute',
                x: { value: 25, anchor: 'leading', unit: 'percent' },
                y: { value: 25, anchor: 'leading', unit: 'percent' }
            }
        },
        stylingProps: {
            background: {
                color: { r: 128, g: 128, b: 128, a: 0.3 }
            }
        }
    };
    addObject(doc, grayRect, { parent: rootFrame.id, index: -1 });

    // Add rect within:
    const rect: EditableFrame = {
        id: 'redRect',
        type: 'editableFrame',
        children: [],
        layout: {
            // xSize: { fixed:'pixels', value: 100 },
            // ySize: { fixed:'pixels', value: 100 },
            // xPosition: { fixed: 'pixels', leading: 50 },
            // yPosition: { fixed: 'pixels', leading: 50 }
            position: {
                kind: 'absolute',
                x: { value: 50, anchor: 'leading', unit: 'pixels' },
                y: { value: 50, anchor: 'leading', unit: 'pixels' }
            },
            xSize: { kind: 'fixed', value: 100, unit: 'pixels' },
            ySize: { kind: 'fixed', value: 100, unit: 'pixels' }
        },
        stylingProps: {
            background: {
                color: { r: 255, g: 0, b: 0, a: 1 }
            }
        }
    };
    // Add a gold rect with a trailing position
    const goldRect: EditableFrame = {
        id: 'goldRect',
        type: 'editableFrame',
        children: [],
        layout: {
            // xSize: { hug: true },
            // ySize: { fixed:'pixels', value: 100 },
            // xPosition: { fixed: 'pixels', leading: 20, trailing: 20 },
            // yPosition: { fixed: 'pixels', trailing: 20 }
            position: {
                kind: 'absolute',
                x: { value: 20, anchor: 'leading', unit: 'pixels' },
                y: { value: 20, anchor: 'trailing', unit: 'pixels' }
            },
            xSize: { kind: 'trailing', offset: 20, unit: 'pixels' },
        },
        stylingProps: {
            background: {
                color: { r: 255, g: 215, b: 0, a: 1 }
            }
        }
    };
    addObject(doc, goldRect, { parent: rootFrame.id, index: -1 });
    // Add a small purple rect with trailing and bottom anchors
    const purpleRect: EditableFrame = {
        id: 'purpleRect',
        type: 'editableFrame',
        children: [],
        layout: {
            // xSize: { fixed:'pixels', value: 50 },
            // ySize: { fixed:'pixels', value: 50 },
            // xPosition: { fixed: 'pixels', trailing: 20 },
            // yPosition: { fixed: 'pixels', trailing: 20 }
            position: {
                kind: 'absolute',
                x: { value: 20, anchor: 'trailing', unit: 'pixels' },
                y: { value: 20, anchor: 'trailing', unit: 'pixels' }
            },
            xSize: { kind: 'fixed', value: 50, unit: 'pixels' },
            ySize: { kind: 'fixed', value: 50, unit: 'pixels' }
        },
        stylingProps: {
            background: {
                color: { r: 128, g: 0, b: 128, a: 1 }
            }
        }
    };
    addObject(doc, purpleRect, { parent: rootFrame.id, index: -1 });

    // Add a turquoise rect with a centered position
    const turquoiseRect: EditableFrame = {
        id: 'turquoiseRect',
        type: 'editableFrame',
        children: [],
        layout: {
            // xSize: { fixed: 'pixels', value: 100 },
            // ySize: { fixed: 'pixels', value: 100 },
            // xPosition: { centeredWithOffset: 0 },
            // yPosition: { fixed: 'pixels', trailing: 50 }
            position: {
                kind: 'absolute',
                x: { value: 0, anchor: 'center', unit: 'pixels' },
                y: { value: 50, anchor: 'trailing', unit: 'pixels' }
            },
            xSize: { kind: 'fixed', value: 100, unit: 'pixels' },
            ySize: { kind: 'fixed', value: 100, unit: 'pixels' }
        },
        stylingProps: {
            background: {
                color: { r: 64, g: 224, b: 208, a: 1 }
            }
        }
    };
    addObject(doc, turquoiseRect, { parent: rootFrame.id, index: -1 });

    // rootFrame.children = [rect.id];
    // doc.objects[rect.id] = rect;
    addObject(doc, rect, { parent: rootFrame.id, index: -1 });

    // Create another rect with some text in it:
    const textRect: EditableFrame = {
        id: 'textContainer',
        type: 'editableFrame',
        children: [],
        layout: {
            xSize: { kind: 'fixed', value: 220, unit: 'pixels' },
            position: {
                kind: 'absolute',
                x: { value: 50, anchor: 'leading', unit: 'pixels' },
                y: { value: 200, anchor: 'leading', unit: 'pixels' }
            },
            padding: paddingAll(10)
        },
        stylingProps: {
            background: {
                color: { r: 0, g: 255, b: 0, a: 1 }
            }
        }
    };
    // rootFrame.children.push(textRect.id);
    // doc.objects[textRect.id] = textRect;
    addObject(doc, textRect, { parent: rootFrame.id, index: -1 });

    // Add text:
    const text: EditableText = {
        id: 'text',
        type: 'editableText',
        text: 'Hello, world! Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        alignment: 'left',
        layout: {}
    };
    // textRect.children = [text.id];
    // doc.objects[text.id] = text;
    addObject(doc, text, { parent: textRect.id, index: -1 });

    // Create another root frame that positions three children using auto layout
    const rootFrame2: EditableFrame = {
        id: 'rootFrame2',
        type: 'editableFrame',
        name: "Frame 2",
        isArtboard: true,
        layout: {
            position: {
                kind: "absolute",
                x: { value: 500, anchor: 'leading', unit: 'pixels' },
                y: { value: 0, anchor: 'leading', unit: 'pixels' }
            },
            xSize: { kind: 'fixed', value: 400, unit: 'pixels' },
            ySize: { kind: 'fixed', value: 600, unit: 'pixels' },
            flexLayout: {
                direction: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch'
            },
            padding: paddingAll(20)
        },
        stylingProps: {
            clip: true,
            background: {
                color: { r: 255, g: 255, b: 255, a: 1 }
            }
        },
        children: []
    };
    // doc.objects[rootFrame2.id] = rootFrame2;
    // doc.rootIds.push(rootFrame2.id);
    addObject(doc, rootFrame2, { parent: null, index: -1 });

    // Add red, green, blue children
    for (const color of ['red', 'green', 'blue']) {
        const child: EditableFrame = {
            id: color,
            type: 'editableFrame',
            children: [],
            layout: {
                xSize: { kind: 'fixed', value: 100, unit: 'percent' },
                ySize: { kind: 'fixed', value: 100, unit: 'pixels' }
            },
            stylingProps: {
                background: {
                    color: { r: color === 'red' ? 255 : 0, g: color === 'green' ? 255 : 0, b: color === 'blue' ? 255 : 0, a: 1 }
                }
            }
        };
        // rootFrame2.children!.push(child.id);
        // doc.objects[child.id] = child;
        addObject(doc, child, { parent: rootFrame2.id, index: -1 });
    }
    // Now add another frame that's the same, but horizontal:
    const rootFrame3: EditableFrame = {
        id: 'rootFrame3',
        type: 'editableFrame',
        name: "Frame 3",
        isArtboard: true,
        layout: {
            position: {
                kind: "absolute",
                x: { value: 1000, anchor: 'leading', unit: 'pixels' },
                y: { value: 0, anchor: 'leading', unit: 'pixels' }
            },
            xSize: { kind: 'fixed', value: 400, unit: 'pixels' },
            ySize: { kind: 'fixed', value: 600, unit: 'pixels' },
            flexLayout: {
                direction: 'row',
                justifyContent: 'flex-start',
                alignItems: 'stretch'
            },
            padding: paddingAll(20)
        },
        stylingProps: {
            clip: true,
            background: {
                color: { r: 255, g: 255, b: 255, a: 1 }
            }
        },
        children: []
    };
    // doc.objects[rootFrame3.id] = rootFrame3;
    // doc.rootIds.push(rootFrame3.id);
    addObject(doc, rootFrame3, { parent: null, index: -1 });

    // Add red, green, blue children
    for (const color of ['red', 'green', 'blue']) {
        const child: EditableFrame = {
            id: color + '2',
            type: 'editableFrame',
            children: [],
            layout: {
                // xSize: { fixed:'pixels', value: 100 },
                // ySize: { fill: true }
                xSize: { kind: 'fixed', value: 100, unit: 'pixels' },
                ySize: { kind: 'fixed', value: 100, unit: 'percent' }
            },
            stylingProps: {
                background: {
                    color: { r: color === 'red' ? 255 : 0, g: color === 'green' ? 255 : 0, b: color === 'blue' ? 255 : 0, a: 1 }
                }
            }
        };
        // rootFrame3.children!.push(child.id);
        // doc.objects[child.id] = child;
        addObject(doc, child, { parent: rootFrame3.id, index: -1 });
    }
    // const np = nearestCommonParent(doc, ['red2', 'green2'])
    // console.log('got:', np, 'expected: rootFrame3')
    // const np2 = nearestCommonParent(doc, ['green2'])
    // console.log('got:', np2, 'expected: rootFrame3')
    // const np3 = nearestCommonParent(doc, ['green2', 'blue'])
    // console.log('got:', np3, 'expected: null')
    return doc;
}
