import { RefObject, useEffect, useState } from "react";
import OpenAI from "openai";
import { BaseObject, HTMLEmbed } from "../../data/model";
import { useEditor } from "../../data/editor";
import { PropButton, SidebarSection, SidebarTextArea } from "./controls";
import { generateHTMLEmbedCode } from "../../data/generation/htmlGen";

interface HTMLEmbedSettingsProps {
    canvasWrapper: RefObject<HTMLDivElement>;
    objects: BaseObject[];
}

export default function HTMLEmbedSettings(props: HTMLEmbedSettingsProps) {
    const { canvasWrapper, objects } = props;
    const valid = objects.length === 1 && objects[0].type === 'htmlEmbed';
    const editor = useEditor();
    const [generating, setGenerating] = useState(false);
    const [prompt, setPrompt] = useState('');
    const objectIds = objects.map(obj => obj.id).join(',');
    const object = objects[0] as HTMLEmbed;

    useEffect(() => {
        if (!valid) {
            return;
        }
        const prompt = (objects[0] as HTMLEmbed).prompt || '';
        setPrompt(prompt)
    }, [objectIds]);

    function generate() {
        if (!valid || prompt === '' || generating) {
            return;
        }
        setGenerating(true);
        generateHTMLEmbedCode(prompt, object.params).then(code => {
            // editor.modify(state => {
            //     const fullHTML = TEMPLATE.replace(TEMPLATE_TOKEN_TO_REPLACE, code);
            //     (state.document.objects[objectIds[0]] as HTMLEmbed).html = fullHTML;
            // });
            // Modify undoable and change prompt
            editor.modifyUndoable(state => {
                const curObj = (objects[0] as HTMLEmbed);
                const oldPrompt = curObj.prompt;
                const oldHTML = curObj.html;
                return {
                    do: state => {
                        const obj = (state.document.objects[objectIds] as HTMLEmbed);
                        obj.prompt = prompt;
                        obj.html = code; // TEMPLATE.replace(TEMPLATE_TOKEN_TO_REPLACE, code);
                    },
                    undo: state => {
                        const obj = (state.document.objects[objectIds] as HTMLEmbed);
                        obj.prompt = oldPrompt;
                        obj.html = oldHTML;
                    }
                }
            });
            setGenerating(false);
        }).catch(e => {
            console.error(e);
            setGenerating(false);
        });
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        e.stopPropagation();
    };

    if (!valid) {
        return null;
    }

    return (
        <SidebarSection>
            <h6>Generate HTML</h6>
            <SidebarTextArea value={prompt} onChange={e => setPrompt(e.target.value)} onKeyDown={onKeyDown} />
            <PropButton onClick={generate} disabled={generating} label="Generate" />
        </SidebarSection>
    )
}
