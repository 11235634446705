import { elementForObjectId } from "../ui/viewHelpers";
import { Editor } from "./editor";
import { CanvasPoint, CanvasRect, ClientRect, InfiniteCanvasPosition, Point, Rect, ViewportPoint, ViewportRect, rectFromClient, rectFromCorners, zeroRect } from "./geo";
import { Document } from "./model";

// Viewport coordinates are client coordinates offset so that the origin is the top-left corner of the viewport

// `viewport` is the client rect of the viewport
export function viewportToCanvasPos(point: ViewportPoint, position: InfiniteCanvasPosition, viewport: ClientRect): CanvasPoint {
    const canvasX = (point.x - viewport.width / 2) / position.zoom - position.centerX;
    const canvasY = (point.y - viewport.height / 2) / position.zoom - position.centerY;
    return { x: canvasX, y: canvasY, space: 'canvas' };
}

export function canvasToViewportPos(point: CanvasPoint, position: InfiniteCanvasPosition, viewport: ClientRect): ViewportPoint {
    const viewportX = (point.x + position.centerX) * position.zoom + viewport.width / 2;
    const viewportY = (point.y + position.centerY) * position.zoom + viewport.height / 2;
    return { x: viewportX, y: viewportY, space: 'viewport' };
}

export function canvasToViewportRect(rect: CanvasRect, position: InfiniteCanvasPosition, viewport: ClientRect): ViewportRect {
    const corner1 = canvasToViewportPos({x: rect.x, y: rect.y, space: 'canvas'}, position, viewport);
    const corner2 = canvasToViewportPos({x: rect.x + rect.width, y: rect.y + rect.height, space: 'canvas'}, position, viewport);
    return rectFromCorners(corner1, corner2);
}

export function viewportToCanvasRect(rect: ViewportRect, position: InfiniteCanvasPosition, viewport: ClientRect): CanvasRect {
    const corner1 = viewportToCanvasPos({x: rect.x, y: rect.y, space: 'viewport'}, position, viewport);
    const corner2 = viewportToCanvasPos({x: rect.x + rect.width, y: rect.y + rect.height, space: 'viewport'}, position, viewport);
    return rectFromCorners(corner1, corner2);
}

export function canvasOffsetForObject(id: string | null, canvasWrapperRef: React.RefObject<HTMLDivElement>): CanvasPoint | null {
    if (id === null) return {x: 0, y: 0, space: 'canvas'};
    const root = canvasWrapperRef.current?.querySelector('.canvas-root');
    if (!root) return null;
    let obj = root.querySelector(`[data-object-id="${id}"]`) as HTMLElement | null;
    if (!obj) return null;
    const offset: CanvasPoint = {x: 0, y: 0, space: 'canvas'};
    while (obj && obj !== root) {
        offset.x += obj.offsetLeft;
        offset.y += obj.offsetTop;
        obj = obj.offsetParent as HTMLElement | null;
    }
    return offset;
}

export function offsetFromParentForObject(id: string, doc: Document, canvasWrapperRef: React.RefObject<HTMLDivElement>): CanvasPoint | null {
    const globalCanvasPos = canvasOffsetForObject(id, canvasWrapperRef);
    const parent = doc.objects[id].parent;
    if (!parent) return globalCanvasPos;
    const parentOffset = canvasOffsetForObject(parent, canvasWrapperRef);
    if (!globalCanvasPos || !parentOffset) return null;
    return {x: globalCanvasPos.x - parentOffset.x, y: globalCanvasPos.y - parentOffset.y, space: 'canvas'};
}

export function canvasSizeForObject(id: string, canvasWrapperRef: React.RefObject<HTMLDivElement>): CanvasPoint | null {
    const element = elementForObjectId(id, canvasWrapperRef);
    if (!element) return null;
    return {x: element.offsetWidth, y: element.offsetHeight, space: 'canvas'};
}

export function canvasBoundingRectForObject(id: string, canvasWrapperRef: React.RefObject<HTMLDivElement>, infiniteCanvasPosition: InfiniteCanvasPosition): CanvasRect | null {
    const element = elementForObjectId(id, canvasWrapperRef);
    if (!element) return null;
    const clientRect = rectFromClient(element.getBoundingClientRect());
    // Correct client rect by subtracting the canvas wrapper's position
    const canvasWrapper = canvasWrapperRef.current;
    if (!canvasWrapper) return null;
    const wrapperRect = rectFromClient(canvasWrapper.getBoundingClientRect());
    clientRect.x -= wrapperRect.x;
    clientRect.y -= wrapperRect.y;
    // Convert to canvas coordinates
    return viewportToCanvasRect({...clientRect, space: 'viewport'}, infiniteCanvasPosition, wrapperRect);
}

export function viewportOffsetForObject(id: string | undefined, editor: Editor): ViewportPoint | null {
    if (!editor.viewportRef) return null;
    const viewportRect: ClientRect = editor.viewportRef && editor.viewportRef.current ? rectFromClient(editor.viewportRef.current.getBoundingClientRect()) : zeroRect('client')
    const clientRect = id ? canvasBoundingRectForObject(id, editor.viewportRef, editor.state.value.canvasPos) : zeroRect('canvas');
    if (!clientRect) return null;
    return canvasToViewportPos({x: clientRect.x, y: clientRect.y, space: 'canvas'}, editor.state.value.canvasPos, viewportRect);
}
