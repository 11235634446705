import { TbArrowAutofitDown, TbArrowAutofitRight, TbArrowsMove, TbArticle, TbCircleDotted, TbEye, TbEyeOff, TbFrame, TbGlobe, TbGridScan, TbRectangle, TbTallymark3, TbTextResize } from "react-icons/tb";
import { Editor, EditorState, useEditor, useEditorDisplayState } from "../../data/editor";
import { BaseObject, Document, EditableFrame, FlexLayout, childrenOf, flexLayoutOf, isArtboard, layoutOf, supportsChildren } from "../../data/model";
import { AccessoryButton, SidebarSection, Spacer } from "./controls";
import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";
import { modifySelectionHandlingUndo } from "../handleKeys";
import { compactMap } from "../../utils/collectionUtils";
import { LayoutMode, getLayoutMode, setLayoutMode } from "./layout";
import { useRandomColor } from "../../utils/renderViz";

interface HierarchyControlProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    objects: BaseObject[]
}

export default function HierarchyControl(props: HierarchyControlProps) {
    const items = useEditorDisplayState(hierarchySnapshot);
    const borderColor = useRandomColor();

    if (items.length === 0) {
        return null;
    }

    const title = props.objects.length === 1 ? 'Children' : 'Objects';

    return (
        <SidebarSection>
            <h6>{title}</h6>
            <TreeContainer>
                {
                    items.map(item => {
                        const {object, indent, secondaryLabel, kind} = item;
                        return <ObjectRow wrapperRef={props.wrapperRef} object={object} indent={indent} kind={kind} key={object.id} secondaryLabel={secondaryLabel} />
                    })
                }
            </TreeContainer>
        </SidebarSection>
    )
}

const TreeContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px;
    background-color: #f3f3f3;
    border: 1px solid #EBE9E6;
    border-radius: 3px;
`;

interface HierarchySnapshotItem {
    object: BaseObject
    indent: number
    secondaryLabel?: string
    kind: 'child' | 'parent' | 'selected' | 'root'
}

function hierarchySnapshot(state: EditorState): HierarchySnapshotItem[] {
    if (state.selectedObjects.size === 0) {
        // Root objects
        // return state.document.rootIds.map(id => ({object: state.document.objects[id], indent: 0, selected: false}));
        return compactMap(state.document.rootIds, id => {
            const obj = state.document.objects[id];
            if (obj) {
                return {object: obj, indent: 0, selected: false, kind: 'root'};
            }
            return undefined;
        });
    }
    if (state.selectedObjects.size > 1) {
        return [];
    }
    const selectedId = state.selectedObjects.values().next().value;
    const selected = state.document.objects[selectedId];
    if (!selected) {
        return [];
    }
    const items: HierarchySnapshotItem[] = [];
    if (selected.parent) {
        const parent = state.document.objects[selected.parent];
        if (parent) {
            items.push({object: parent, indent: 0, secondaryLabel: 'Parent', kind: 'parent'});
        }
    }
    items.push({object: selected, indent: selected.parent ? 1 : 0, kind: 'selected'});
    for (const childId of childrenOf(selected)) {
        const child = state.document.objects[childId];
        if (child) {
            items.push({object: child, indent: selected.parent ? 2 : 1, kind: 'child'});
        }
    }
    return items;
}

interface ObjectRowProps {
    object: BaseObject
    indent: number
    secondaryLabel?: string
    wrapperRef: React.RefObject<HTMLDivElement>
    kind: HierarchySnapshotItem['kind']
}

function ObjectRow(props: ObjectRowProps) {
    const {object, indent, secondaryLabel, wrapperRef, kind} = props;
    const {icon, name} = iconAndDisplayName(object);
    const editor = useEditor();
    const onClick = useCallback(() => {
        modifySelectionHandlingUndo(editor, (selection, doc) => {
            selection.clear();
            selection.add(object.id);
        });
    }, [object.id, editor]);
    const showFlexControls = kind === 'parent' || kind === 'selected';

    return (
        <ObjectRowDiv style={{marginLeft: indent * 10}} $selected={kind === 'selected'} onClick={onClick}>
            {icon}
            {name}
            {secondaryLabel && <SecondaryLabel>{secondaryLabel}</SecondaryLabel>}
            <Spacer />
            { object.parent && <LayoutModeToggle object={object} wrapperRef={wrapperRef} /> }
            { showFlexControls ? <FlexLayoutToggles object={object} wrapperRef={wrapperRef} /> : null }
            <VisibilityToggle object={object} editor={editor} />
        </ObjectRowDiv>
    )
}

const ObjectRowDiv = styled.div<{$selected: boolean}>`
    display: flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 13px;

    background-color: ${props => props.$selected ? 'white' : 'transparent'};
    border-radius: ${props => props.$selected ? '2px' : '0'};
    box-shadow: ${props => props.$selected ? '0 1px 2px rgba(0,0,0,0.1)' : 'none'};

    &:hover {
        background-color: rgba(0,0,0,0.05);
    }

    > *:not(:last-child) {
        margin-right: 6px;
    }
`;

const SecondaryLabel = styled.div`
    margin-left: 0.5em;
    opacity: 0.5;
`;

function iconAndDisplayName(object: BaseObject): {icon: ReactNode, name: string} {
    if (object.type === 'editableFrame') {
        if (isArtboard(object)) {
            const objAsEditableFrame = object as EditableFrame;
            return {icon: <TbFrame />, name: object.name ?? 'Artboard'};
        }
        return {icon: <TbRectangle />, name: object.name ?? 'Frame'};
    }
    if (object.type === 'editableText') {
        return {icon: <TbTextResize />, name: object.name ?? 'Text'};
    }
    if (object.type === 'htmlEmbed') {
        return {icon: <TbGlobe />, name: object.name ?? 'HTML'};
    }
    return {icon: <TbCircleDotted />, name: object.name ?? 'Object'};
}

function VisibilityToggle({object, editor}: {object: BaseObject, editor: Editor}) {
    const hidden = object.hidden ?? false;
    const toggleVis = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        editor.modifyUndoable(state => {
            function toggle(state: EditorState) {
                const obj = state.document.objects[object.id];
                if (obj) {
                    obj.hidden = !obj.hidden;
                }
            }
            return {
                do: toggle,
                undo: toggle
            }
        });
    }, [object.id, hidden, editor]);

    return (
        <AccessoryButton onClick={toggleVis} selected={hidden}>
            { hidden ? <TbEyeOff /> : <TbEye /> }
        </AccessoryButton>
    )
}

function LayoutModeToggle({object, wrapperRef}: {object: BaseObject, wrapperRef: React.RefObject<HTMLDivElement>}) {
    const layoutMode = getLayoutMode(layoutOf(object));
    const editor = useEditor();
    const toggleLayoutMode = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const newMode: LayoutMode = layoutMode === 'inline' ? 'absolute' : 'inline';
        setLayoutMode(editor, newMode, object.id, wrapperRef);
    }, [object.id, layoutMode, wrapperRef]);

    return (
        <AccessoryButton onClick={toggleLayoutMode} selected={false}>
            { layoutMode === 'inline' ? <TbArticle /> : <TbArrowsMove /> }
        </AccessoryButton>
    )
}

function FlexLayoutToggles({object, wrapperRef}: {object: BaseObject, wrapperRef: React.RefObject<HTMLDivElement>}) {
    const flex = flexLayoutOf(object);
    const direction: 'row' | 'column' = flex?.direction ?? 'row';
    const icon = direction === 'row' ? <TbArrowAutofitRight /> : <TbArrowAutofitDown />;
    const editor = useEditor();

    const toggleDirection = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const newDirection = direction === 'row' ? 'column' : 'row';
        modifyFlexLayout(editor, object, flex => {
            flex.direction = newDirection;
        });
        // TODO: If all children are absolute position, give them fixed position
    }, [object.id, direction, editor]);

    if (childrenOf(object).length === 0) {
        return null;
    }

    return (
        <AccessoryButton onClick={toggleDirection} selected={false}>
            {icon}
        </AccessoryButton>
    )
}

function modifyFlexLayout(editor: Editor, object: BaseObject, modify: (flex: FlexLayout) => void) {
    editor.modifyUndoable(state => {
        const oldFlex: FlexLayout = flexLayoutOf(object) || { direction: 'row', alignItems: 'stretch', justifyContent: 'flex-start' };
        const newFlex: FlexLayout = {...oldFlex};
        modify(newFlex);

        function setLayout(state: EditorState, layout: FlexLayout | undefined) {
            const obj = state.document.objects[object.id];
            if (!obj) { return }
            if (obj.type === 'editableFrame') {
                const editableFrame = obj as EditableFrame;
                if (!editableFrame.layout) {
                    editableFrame.layout = {};
                }
                editableFrame.layout.flexLayout = layout;
            }
        }

        return {
            do: state => {
                setLayout(state, newFlex);
            },
            undo: state => {
                setLayout(state, oldFlex);
            }
        }
    });
}
