import { CSSProperties, createContext, memo, useContext, useEffect, useRef } from "react";
import { EditableFrame, EditableText, EditableTextField, HTMLEmbed, childrenOf, cssForObject, getFixedLeadingPosition, layoutNeedsWrapper, layoutOf, wrapperCSS } from "../../data/model";
import { useEditorDisplayState, useEditorState } from "../../data/editor";
import EditableTextView from "./EditableTextView";
import EmbedFrame from "./EmbedFrame";

interface DocumentRenderOptions {
    renderingToString: boolean;
}

export const DocumentRenderContext = createContext<DocumentRenderOptions>({ renderingToString: false });

function _DocumentView() {
    const rootIds = useEditorDisplayState(state => state.document.rootIds);
    return (
        <div className="document" style={{ width: '100%', height: '100%', overflow: 'hidden', color: 'black' }}>
            {rootIds.map(id => {
                return <ObjectView key={id} id={id} />
            })}
        </div>
    );
}

export const DocumentView = memo(_DocumentView);

interface ObjectViewProps {
    id: string
    renderToStringRoot?: boolean;
}

function _ObjectView(props: ObjectViewProps) {
    const renderToStringRoot = props.renderToStringRoot || false;
    const object = useEditorDisplayState(state => state.document.objects[props.id]);
    const { renderingToString } = useContext(DocumentRenderContext);

    if (!object) {
        return null;
    }

    const idProps: any = renderingToString ? {} : { 'data-object-id': object.id };
    if (object.name) {
        idProps['data-comment'] = object.name;
    };

    const css = cssForObject(object);
    // css['border'] = `2px solid ${randomColor()}`;
    css['boxSizing'] = 'border-box';
    if (renderToStringRoot) {
        delete css.left;
        delete css.top;
        css.width = '100%';
        css.height = '100%';
    }

    const children = childrenOf(object).map(id => {
        return <ObjectView key={id} id={id} />;
    });

    let content: React.ReactNode | null = null;

    if (object.type === 'editableFrame') {
        const frame = object as EditableFrame;
        if (frame.displayAsCode && frame.code && !renderingToString) {
            content = <LiveCodeFrame object={object as HTMLEmbed} src={frame.code} />;
        } else {
            content = (
                <>
                    <div style={css} {...idProps}>
                        {children}
                    </div>
                    {frame.isArtboard && !frame.parent && !renderingToString ? <ArtboardLabel frame={frame} key="__label" /> : null}
                </>
            );
        }
    }
    if (object.type === 'editableText') {
        css['display'] = 'flex';
        content = (
            <div style={css} {...idProps}>
                <EditableTextView object={object as EditableText} />
            </div>
        )
    }
    if (object.type === 'editableTextField') {
        const field = object as EditableTextField;
        content = <input style={css} {...idProps} placeholder={field.placeholder} value={field.value} />;
    }
    if (object.type === 'htmlEmbed') {
        content = <HTMLEmbedView object={object as HTMLEmbed} {...idProps} />;
    }
    if (!content) {
        content = <div {...idProps}>Unknown object type: {object.type}</div>;
    }

    const needsWrapper = layoutNeedsWrapper(layoutOf(object));
    if (needsWrapper) {
        return (
            <div style={wrapperCSS(layoutOf(object))}>
                {content}
            </div>
        )
    } else {
        return content;
    }
}
export const ObjectView = memo(_ObjectView);

// Render viz

interface ArtboardLabelProps {
    frame: EditableFrame
}

function ArtboardLabel(props: ArtboardLabelProps) {
    const { frame } = props;
    // We expect an absolute position for these
    const {x,y} = getFixedLeadingPosition(frame.layout) || {x: 0, y: 0};
    // const x = getFixedLeadingPosition(frame.layout.xPosition);
    // const y = getFixedLeadingPosition(frame.layout.yPosition);
    const css: CSSProperties = {
        position: 'absolute',
        left: x + 'px',
        top: y + 'px',
        marginTop: '-1.8em',
        padding: '0px 4px',
        color: 'black',
        opacity: 0.5,
        fontSize: '12px',
        zIndex: 1000,
        cursor: 'grab',
        // do not allow wrap
        whiteSpace: 'nowrap',
    }
    // TODO: click to rename using prompt() (implement in clickHandlers)
    return <div style={css} data-object-handle-id={frame.id}>{frame.name || "Artboard"}</div>;
}

interface HTMLEmbedViewProps {
    object: HTMLEmbed
}

// Create a wrapper view, with a handle for the object that extends 3px outside the object. Then embed the html using an iframe (make sure to only update when the html changes)
function HTMLEmbedView(props: HTMLEmbedViewProps) {
    const { object } = props;
    const css = cssForObject(object);
    const html = object.html;
    const iframeInteractive = useEditorState(state => state.selectedObjects.has(object.id) && state.movesInProgress.length === 0 && state.resizesInProgress.length === 0 && !state.penMode);
    return (
        <div style={css} data-object-id={object.id}>
            <div data-object-handle-id={object.id} style={{ position: 'absolute', left: '-5px', top: '-5px', width: 'calc(100% + 10px)', height: 'calc(100% + 10px)', cursor: 'grab' }} />
            <EmbedFrame html={html} interactive={iframeInteractive} params={object.params} />
        </div>
    );
}

interface LiveCodeFrameProps {
    src: string
    object: HTMLEmbed
}
function LiveCodeFrame({ src, object }: LiveCodeFrameProps) {
    const css = cssForObject(object);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const iframeInteractive = useEditorState(state => state.selectedObjects.has(object.id) && state.movesInProgress.length === 0 && state.resizesInProgress.length === 0 && !state.penMode);
    useEffect(() => {
        if (iframeRef.current) {
            // Clear doc and set src html via srcDoc
            iframeRef.current.srcdoc = src;
            console.log('set srcdoc', src);
        }
    }, [src]);
    return (
        <div style={css} data-object-id={object.id}>
            <iframe data-object-id={object.id} style={{ pointerEvents: iframeInteractive ? 'auto' : 'none', position: 'absolute', width: '100%', height: '100%', border: 'none' }} ref={iframeRef} />
        </div>
    );
}
