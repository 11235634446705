import { RGBColor, SketchPicker } from "react-color";
import { Popover } from "react-tiny-popover";
import { PropButtonWithPreview } from "./controls";
import { useState } from "react";
import { Color, Fill } from "../../data/model";
import { v4 as uuidv4 } from "uuid";

interface SingleColorPickerProps {
    color: Color
    onChange: (color: Color, gestureId: string) => void
}

export function SingleColorPicker(props: SingleColorPickerProps) {
    const {color, onChange} = props;
    const [gestureId, setGestureId] = useState<string>(() => uuidv4());
    const picker = (
        <SketchPicker 
            color={color} 
            onChange={color => onChange(pickerToNativeColor(color.rgb), gestureId)} 
            onChangeComplete={ _ => setGestureId(uuidv4())} 
        />);
    const [popoverOpen, setPopoverOpen] = useState(false);
    return (
        <Popover content={picker} isOpen={popoverOpen} onClickOutside={() => setPopoverOpen(false)}>
            <div>
                <PropButtonWithPreview label="Color" onClick={() => setPopoverOpen(true)}>
                    <ColorPreview color={color} />
                </PropButtonWithPreview>
            </div>
        </Popover>
    )
}

interface FillPickerProps {
    fill: Fill
    onChange: (fill: Fill, gestureId: string) => void
}

export function FillPicker(props: FillPickerProps) {
    const {fill, onChange} = props;
    const [gestureId, setGestureId] = useState<string>(() => uuidv4());
    const picker = (
        <SketchPicker 
            color={fill.color} 
            onChange={color => onChange({ color: pickerToNativeColor(color.rgb) }, gestureId)} 
            onChangeComplete={ _ => setGestureId(uuidv4())} 
        />);
    const [popoverOpen, setPopoverOpen] = useState(false);
    return (
        <Popover content={picker} isOpen={popoverOpen} onClickOutside={() => setPopoverOpen(false)}>
            <div>
                <PropButtonWithPreview label="Fill" onClick={() => setPopoverOpen(true)}>
                    <FillPreview fill={fill} />
                </PropButtonWithPreview>
            </div>
        </Popover>
    )
}

// Just redirect to FillPreview
export function ColorPreview(props: {color: Color}) {
    return <FillPreview fill={{color: props.color}} />
}

export function FillPreview(props: {fill: Fill}) {
    const {fill} = props;
    return (
        <div style={{backgroundColor: `rgba(${fill.color.r},${fill.color.g},${fill.color.b},${fill.color.a})`, width: 16, height: 16, borderRadius: 3}} />
    )
}

export function pickerToNativeColor(color: RGBColor): Color {
    return {r: color.r, g: color.g, b: color.b, a: color.a || 1}
}