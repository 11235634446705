import styled from "styled-components";
import { CanvasPoint } from "../../data/geo";
import { BaseObject, LayoutProps, Positioning, layoutOf } from "../../data/model";
import clone from 'rfdc';
import { useState } from "react";
import { Constraint, Constraints, constraintsFromLayout, modifyConstraintsOnView } from "../../data/updateLayout";
import { useEditor } from "../../data/editor";
import { replaceInline } from "../../utils/replaceAllKeys";

const copy = clone();

interface ConstraintsUnitProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    objects: BaseObject[]
}

export default function ConstraintsUnit(props: ConstraintsUnitProps) {
    const { wrapperRef, objects } = props;
    const editor = useEditor();
    if (objects.length !== 1) {
        return null;
    }
    const constraints = constraintsFromLayout(layoutOf(objects[0]));
    const setConstraints = (constraints: Constraints) => {
        editor.modifyUndoable(state => {
            const oldLayout = layoutOf(objects[0]);
            return {
                undo: (state) => {
                    const obj = state.document.objects[objects[0].id];
                    if (obj) {
                        replaceInline(oldLayout, layoutOf(obj));
                    }
                },
                do: (state) => {
                    const newLayout = modifyConstraintsOnView(state, objects[0].id, wrapperRef, constraints);
                    console.log('newLayout', newLayout);
                    const obj = state.document.objects[objects[0].id];
                    if (newLayout && obj) {
                        replaceInline(newLayout, layoutOf(obj));
                    }
                }
            }
        });
    }

    return (
        <ConstraintsView constraints={constraints} onChange={setConstraints} />
    );
}

interface ConstraintsViewProps {
    constraints: Constraints;
    onChange: (constraints: Constraints) => void;
}

export function ConstraintsView(props: ConstraintsViewProps) {
    const { constraints, onChange } = props;

    function clickedConstraint(constraint: Constraint, axis: 'x' | 'y', shiftHeld: boolean) {
        const newConstraints = copy(constraints);
        if (shiftHeld) {
            // Toggle
            if (constraints[axis].has(constraint)) {
                newConstraints[axis].delete(constraint);
            } else {
                newConstraints[axis].add(constraint);
            }    
        } else {
            // Set the constraint for this axis
            newConstraints[axis] = new Set([constraint]);
        }
        onChange(newConstraints);
    }

    return (
        <ConstraintsContainer>
            <ConstraintGroup>
                <ConstraintBar value={constraints.x.has(Constraint.leading)} onClick={(e) => clickedConstraint(Constraint.leading, 'x', e.shiftKey)} />
                <ConstraintBar value={constraints.x.has(Constraint.center)} onClick={(e) => clickedConstraint(Constraint.center, 'x', e.shiftKey)} />
                <ConstraintBar value={constraints.x.has(Constraint.trailing)} onClick={(e) => clickedConstraint(Constraint.trailing, 'x', e.shiftKey)} />
            </ConstraintGroup>

            <ConstraintGroup style={{transform: `rotate(90deg)` }}>
                <ConstraintBar value={constraints.y.has(Constraint.leading)} onClick={(e) => clickedConstraint(Constraint.leading, 'y', e.shiftKey)} />
                <ConstraintBar value={constraints.y.has(Constraint.center)} onClick={(e) => clickedConstraint(Constraint.center, 'y', e.shiftKey)} />
                <ConstraintBar value={constraints.y.has(Constraint.trailing)} onClick={(e) => clickedConstraint(Constraint.trailing, 'y', e.shiftKey)} />
            </ConstraintGroup>
        </ConstraintsContainer>
    );
}

const ConstraintsContainer = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border: 1px solid red;
    border-radius: 3px;
    border: 1px solid #EBE9E6;
`;

const ConstraintGroup = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;

const ConstraintBar = styled.div<{value: boolean}>`
    flex: 2;
    &:first-child, &:last-child {
        flex: 1;
    }
    background-color: blue;
    height: 8px;
    pointer-events: auto;
    box-sizing: border-box;
    border: 3px solid white;
    align-self: center;
    opacity: ${props => props.value ? 1 : 0.2};
    &:hover {
    outline: 1px solid black;
    }
`;

