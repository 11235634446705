import { useState } from "react";
import { Shadow } from "../../data/model";
import { v4 as uuidv4 } from "uuid";
import { MultiPropRow, NumberInput, PropButton, PropButtonWithPreview } from "./controls";
import { ColorPreview, pickerToNativeColor } from "./colorPickers";
import { Popover } from "react-tiny-popover";
import styled from "styled-components";
import { SketchPicker } from "react-color";

interface ShadowPickerProps {
    shadow: Shadow | undefined;
    onChange: (shadow: Shadow | undefined, gestureId: string) => void
}

export function ShadowPicker(props: ShadowPickerProps) {
    const {shadow, onChange} = props;
    // const [gestureId, setGestureId] = useState<string>(() => uuidv4());
    const defaultShadow: Shadow = {
        x: 4,
        y: 4,
        blur: 10,
        color: { r: 0, g: 0, b: 0, a: 0.15 }
    };
    const [popoverOpen, setPopoverOpen] = useState(false);
    const content = <ShadowPickerContent shadow={shadow || defaultShadow} onChange={onChange} onRemove={() => {
        onChange(undefined, uuidv4());
        setPopoverOpen(false);
    }} />;
    return (
        <Popover content={content} isOpen={popoverOpen} onClickOutside={() => setPopoverOpen(false)}>
            <div>
                <PropButtonWithPreview label="Shadow" onClick={() => setPopoverOpen(true)}>
                    <ShadowPreview shadow={shadow} />
                </PropButtonWithPreview>
            </div>
        </Popover>
    )
}

interface ShadowPickerContentProps {
    shadow: Shadow;
    onChange: (shadow: Shadow, gestureId: string) => void;
    onRemove: () => void;
}

function ShadowPickerContent(props: ShadowPickerContentProps) {
    const [gestureId, setGestureId] = useState<string>(() => uuidv4());
    const {shadow, onChange, onRemove} = props;

    return (
        <ShadowPickerContentDiv>
            <SketchPicker color={shadow.color} onChange={color => onChange({...shadow, color: pickerToNativeColor(color.rgb)}, gestureId)} />
            <MultiPropRow>
                <NumberInput label="X" value={shadow.x} onChange={x => onChange({...shadow, x}, gestureId)} />
                <NumberInput label="Y" value={shadow.y} onChange={y => onChange({...shadow, y}, gestureId)} />
            </MultiPropRow>
            <NumberInput label="Blur" value={shadow.blur} onChange={blur => onChange({...shadow, blur}, gestureId)} />
            <PropButton label="Remove Shadow" onClick={onRemove} />
        </ShadowPickerContentDiv>
    );
}

const ShadowPickerContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 8px;
    background: white;
    border-radius: 4px;
    border: 1px solid #ccc;
`;

function ShadowPreview({shadow}: {shadow?: Shadow}) {
    if (!shadow) return <div>None</div>;

    return <ColorPreview color={shadow.color} />;
}

    // const picker = (
    //     <SketchPicker 
    //         color={fill.color} 
    //         onChange={color => onChange({ color: pickerToNativeColor(color.rgb) }, gestureId)} 
    //         onChangeComplete={ _ => setGestureId(uuidv4())} 
    //     />);
