import { BaseObject, FlexLayout, LayoutProps, Padding, Sizing, childrenOf, isAbsolutePosition, layoutOf } from "../../data/model";
import equal from 'fast-deep-equal/es6';
import { AccessoryButton, MultiPropRow, NumberInput, PropButton, SidebarSection, SidebarSegmentedControl, Spacer, TwoColumnPropGroup } from "./controls";
import { Editor, EmptyUndoableOperation, useEditor, useEditorDisplayState } from "../../data/editor";
import { canvasBoundingRectForObject, canvasOffsetForObject, canvasSizeForObject } from "../../data/coordinates";
import { CanvasPoint, CanvasRect } from "../../data/geo";
import { elementForObjectId } from "../viewHelpers";
import { TbAlignCenter, TbAlignLeft, TbAlignRight, TbArrowAutofitHeight, TbArrowAutofitWidth, TbArrowDown, TbArrowRight, TbFold, TbLayoutGrid, TbPercentage, TbViewportNarrow, TbViewportWide } from "react-icons/tb";
import { useState } from "react";
import ConstraintsUnit from "./constraints";
import { replaceInline } from "../../utils/replaceAllKeys";
import { useRandomColor } from "../../utils/renderViz";
import styled from "styled-components";

// // Import all the icons for `TbAlignBoxLeftTop`, `TbAlignBoxTopCenter`, `TbAlignBoxLeftTop`, etc.. all 18 permutations
// import { TbAlignBoxBottomCenter, TbAlignBoxBottomLeft, TbAlignBoxBottomRight, TbAlignBoxCenterMiddle, TbAlignBoxCenterTop, TbAlignBoxLeftBottom, TbAlignBoxLeftMiddle, TbAlignBoxLeftTop, TbAlignBoxMiddleMiddle, TbAlignBoxRightBottom, TbAlignBoxRightMiddle, TbAlignBoxRightTop, TbAlignBoxTopCenter, TbAlignBoxTopLeft, TbAlignBoxTopRight } from "react-icons/tb";

interface LayoutControlProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    objects: BaseObject[]
}

export default function LayoutControls(props: LayoutControlProps) {
    const {wrapperRef, objects} = props;
    const editor = useEditor();
    const allObjectsHaveLayout = objects.every(obj => layoutOf(obj) !== null);
    const [showAllPadding, setShowAllPadding] = useState(false);
    const borderColor = useRandomColor();
    const objectIds = objects.map(obj => obj.id).join(',');
    const allObjectsHaveParentWithAutoLayout = useEditorDisplayState(s => {
        return objects.every(obj => {
            if (!obj.parent) { return false; }
            const parentLayout = layoutOf(s.document.objects[obj.parent]);
            return parentLayout && !!parentLayout.flexLayout;
        });
    }, [objectIds])

    if (!allObjectsHaveLayout || objects.length === 0) {
        return null;
    }
    const sharedLayout: LayoutProps = ifAllEqual(objects.map(obj => layoutOf(obj))) || {};
    const layoutMode = getLayoutMode(sharedLayout);
    const layoutModeOptions: LayoutMode[] = ['inline', 'absolute'];
    function onLayoutModeChange(mode: LayoutMode) {
        objects.forEach(obj => {
            setLayoutMode(editor, mode, obj.id, wrapperRef);
        });
    }

    const allObjectsAreAutoLayout = objects.every(obj => layoutOf(obj)?.flexLayout !== undefined);
    const allObjectsCanEnterAutoLayout = objects.every(obj => obj.type === 'editableFrame' && childrenOf(obj).length > 0);
    const showAutoLayoutSection = allObjectsAreAutoLayout;
    const showAutoLayoutAdderButton = allObjectsCanEnterAutoLayout && !allObjectsAreAutoLayout;

    function addAutoLayoutToSelection() {
        addAutoLayout(editor, wrapperRef, objects.map(obj => obj.id));
    }

    // If objects do have fixed position and are in not in auto-layout parents, we can omit the layout mode control.
    // (Ideally you wouldnt be able to get an inline position without an auto-layout parent but this is not enforced in the data model)
    const canChangeLayoutMode = objects.every(obj => !!obj.parent) && (allObjectsHaveParentWithAutoLayout || layoutMode === 'inline');

    function updatePadding(padding: Partial<Padding>) {
        objects.forEach(obj => {
            modifyLayout(editor, obj.id, wrapperRef, (layout, pos) => {
                layout.padding = {...(layout.padding || {left: 0, right: 0, top: 0, bottom: 0}), ...padding};
            });
        });
    }
    const showPaddingControls = allObjectsAreAutoLayout || objects.every(obj => obj.type === 'editableTextField' || obj.type === 'editableText');
    function paddingControls() {
        if (showAllPadding) {
            return (
                <>
                    <MultiPropRow>
                        <NumberInput label="Top" value={sharedLayout.padding?.top || 0} onChange={value => updatePadding({top: value})} />
                        <NumberInput label="Bottom" value={sharedLayout.padding?.bottom || 0} onChange={value => updatePadding({bottom: value})} />
                    </MultiPropRow>
                    <MultiPropRow>
                        <NumberInput label="Left" value={sharedLayout.padding?.left || 0} onChange={value => updatePadding({left: value})} />
                        <NumberInput label="Right" value={sharedLayout.padding?.right || 0} onChange={value => updatePadding({right: value})} />
                    </MultiPropRow>
                </>
            );
        }
        // Else just show horiz and vert
        return (
            <MultiPropRow>
                <NumberInput label="X" value={sharedLayout.padding?.left || 0} onChange={value => updatePadding({left: value, right: value})} />
                <NumberInput label="Y" value={sharedLayout.padding?.top || 0} onChange={value => updatePadding({top: value, bottom: value})} />
            </MultiPropRow>
        );
    }

    return (
        <>
            <SidebarSection>
                <h6>Layout</h6>
                { canChangeLayoutMode ? <SidebarSegmentedControl options={layoutModeOptions} selection={layoutMode} viewForOption={viewForLayoutMode} onChange={onLayoutModeChange} /> : null }
                <WidthHeightSlider layoutKey="xSize" objects={objects} wrapperRef={wrapperRef} />
                <WidthHeightSlider layoutKey="ySize" objects={objects} wrapperRef={wrapperRef} />
                { layoutMode === 'absolute' && <ConstraintsUnit wrapperRef={wrapperRef} objects={objects} /> }
                { showAutoLayoutAdderButton && <PropButton onClick={addAutoLayoutToSelection} label="Add Auto Layout" /> }
            </SidebarSection>
            {
                showAutoLayoutSection && (
                    <AutoLayoutSection wrapperRef={wrapperRef} objects={objects} />
                )
            }
            {
                showPaddingControls && (
                    <SidebarSection>
                        <MultiPropRow>
                            <h6>Padding</h6>
                            <Spacer />
                            <AccessoryButton selected={showAllPadding} onClick={() => setShowAllPadding(!showAllPadding)}><TbLayoutGrid /></AccessoryButton>
                        </MultiPropRow>
                        {paddingControls()}
                    </SidebarSection>
                )
            }
        </>
    )
}

function viewForLayoutMode(mode: LayoutMode): string {
    switch (mode) {
        case 'inline': return 'Inline Position';
        case 'absolute': return 'Fixed Position';
    }
}

export type LayoutMode = 'absolute' | 'inline';

export function getLayoutMode(props: LayoutProps | undefined | null): LayoutMode | undefined {
    if (!props) { return undefined; }
    if (props.position) {
        return props.position.kind;
    }
    return 'inline';
}

interface WidthHeightSliderProps {
    layoutKey: 'xSize' | 'ySize';
    objects: BaseObject[];
    wrapperRef: React.RefObject<HTMLDivElement>;
}

function WidthHeightSlider(props: WidthHeightSliderProps) {
    const {layoutKey, objects, wrapperRef} = props;
    const sharedSizing = ifAllEqual(objects.map(obj => layoutOf(obj)?.[layoutKey])) || undefined;
    const editor = useEditor();
    
    let fixedValue: number | undefined;
    let pct = false;
    let hug = false;
    let fill = false;
    if (sharedSizing) {
        switch (sharedSizing.kind) {
            case 'fixed':
                fixedValue = sharedSizing.value;
                pct = sharedSizing.unit === 'percent';
                if (pct && fixedValue === 100) {
                    fill = true;
                }
                break;
            case 'hug':
                hug = true;
                break;
        }
        // if ('fixed' in sharedSizing) {
        //     fixedValue = sharedSizing.value;
        //     pct = sharedSizing.fixed === 'percent';
        // }
        // if ('hug' in sharedSizing) {
        //     hug = sharedSizing.hug;
        // }
        // if ('fill' in sharedSizing) {
        //     fill = sharedSizing.fill;
        // }
    }

    function setSizing(sizing: Sizing) {
        objects.forEach(obj => {
            modifyLayout(editor, obj.id, wrapperRef, (layout, pos) => {
                layout[layoutKey] = sizing;
            });
        });
    }

    const setFixedValue = (value: number) => {
        const unit = pct ? 'percent' : 'pixels';
        const newSizing: Sizing = {kind: 'fixed', value, unit};
        setSizing(newSizing);
    };

    const toggleHug = () => {
        if (hug) {
            setFixedSizeAsPercent(false);
        } else {
            setSizing({kind: 'hug'});
        }
    };

    const toggleFill = () => {
        if (fill) {
            setFixedSizeAsPercent(false);
        } else {
            setSizing({kind: 'fixed', value: 100, unit: 'percent'});
        }
    }

    function setFixedSizeAsPercent(percent: boolean) {
        objects.forEach(obj => {
            modifyLayout(editor, obj.id, wrapperRef, (layout, pos, parentSize) => {
                if (parentSize) {
                    if (percent) {
                        // layout[layoutKey] = {fixed: 'percent', value: pos.width / parentSize[layoutKey === 'xSize' ? 'x' : 'y'] * 100};
                        layout[layoutKey] = {kind: 'fixed', value: pos.width / parentSize[layoutKey === 'xSize' ? 'x' : 'y'] * 100, unit: 'percent'};
                    } else {
                        // layout[layoutKey] = {fixed: 'pixels', value: pos.width};
                        layout[layoutKey] = {kind: 'fixed', value: pos.width, unit: 'pixels'};
                    }
                }
            });
        });
    }

    const rotation = layoutKey === 'xSize' ? 90 : 0;
    const transform = `rotate(${rotation}deg) translateX(-1px) translateY(2px)`;
    const fillIcon = layoutKey === 'xSize' ? <TbArrowAutofitWidth /> : <TbArrowAutofitHeight />;
    
    const accessories = (
        <>
            <AccessoryButton selected={pct} onClick={() => setFixedSizeAsPercent(!pct)}><TbPercentage /></AccessoryButton>
            <AccessoryButton selected={hug} onClick={toggleHug} style={{transform}}><TbFold /></AccessoryButton>
            <AccessoryButton selected={fill} onClick={toggleFill}>{ fillIcon }</AccessoryButton>
        </>
    )

    return (
        <NumberInput label={layoutKey === 'xSize' ? 'Width' : 'Height'} value={fixedValue || 0} onChange={setFixedValue} accessories={accessories} />
    )
}

function ifAllEqual<T>(items: T[]): T | undefined {
    if (items.length === 0) {
        return undefined;
    }
    const first = items[0];
    if (items.every(item => equal(item, first))) {
        return first;
    }
    return undefined;
}

// undoable
export function setLayoutMode(editor: Editor, mode: LayoutMode, objId: string, wrapperRef: React.RefObject<HTMLDivElement>) {
    const hasParent = !!editor.state.value.document.objects[objId].parent;
    if (!hasParent && mode === 'inline') { return; }
    switch (mode) {
        case 'inline':
            modifyLayout(editor, objId, wrapperRef, (layout, pos) => {
                layout.position = {kind: 'inline'};
            });
            break;
        case 'absolute':
            modifyLayout(editor, objId, wrapperRef, (layout, pos) => {
                // layout.xPosition = {fixed: 'pixels', leading: pos.x};
                // layout.yPosition = {fixed: 'pixels', leading: pos.y};
                layout.position = {kind: 'absolute', x: {value: pos.x, unit: 'pixels', anchor: 'leading'}, y: {value: pos.y, unit: 'pixels', anchor: 'leading'}};
                if (!layout.xSize || layout.xSize.kind !== 'fixed') {
                    layout.xSize = {kind: 'fixed', value: pos.width, unit: 'pixels'};
                }
                if (!layout.ySize || layout.ySize.kind !== 'fixed') {
                    layout.ySize = {kind: 'fixed', value: pos.height, unit: 'pixels'};
                }
            });
            break;
    }
}

function modifyLayout(editor: Editor, objId: string, wrapperRef: React.RefObject<HTMLDivElement>, edit: (layout: LayoutProps, posInParentRect: CanvasRect, parentSize: CanvasPoint | undefined) => void) {
    // use bounding rect to find size, then compute offset from parent
    const rect = canvasBoundingRectForObject(objId, wrapperRef, editor.state.value.canvasPos);
    const objectElement = elementForObjectId(objId, wrapperRef);
    if (!rect || !objectElement) { return; }
    rect.x = objectElement.offsetLeft;
    rect.y = objectElement.offsetTop;
    const obj = editor.state.value.document.objects[objId];
    if (!obj) { return; }
    const parentSizeOrNull = obj.parent ? canvasSizeForObject(obj.parent, wrapperRef) : undefined;
    const parentSize = parentSizeOrNull === null ? undefined : parentSizeOrNull;

    editor.modifyUndoable(state => {
        // Store old layout
        const obj = state.document.objects[objId];
        if (!obj) { return EmptyUndoableOperation }
        const oldLayout = layoutOf(obj);
        if (!oldLayout) { return EmptyUndoableOperation }

        return {
            do: state => {
                const obj = state.document.objects[objId];
                if (!obj) { return state; }
                const layout = layoutOf(obj);
                if (!layout) { return state; }
                edit(layout, rect, parentSize);
            },
            undo: state => {
                const obj = state.document.objects[objId];
                if (!obj) { return state; }
                const layout = layoutOf(obj);
                if (!layout) { return state; }
                replaceInline(oldLayout, layout);
            }
        }
    });
}

// Undoable
export function addAutoLayout(editor: Editor, wrapperRef: React.RefObject<HTMLDivElement>, objectIds: string[]) {
    // First, modify layout to add flex (if not already there)
    objectIds.forEach(objId => {
        modifyLayout(editor, objId, wrapperRef, (layout, pos) => {
            layout.flexLayout = {direction: 'column', gap: 0, justifyContent: 'flex-start', alignItems: 'flex-start'};
        });
    });
    const allChildren = objectIds.flatMap(objId => {
        const obj = editor.state.value.document.objects[objId];
        if (!obj) { return []; }
        return childrenOf(obj);
    });
    allChildren.forEach(childId => {
        setLayoutMode(editor, 'inline', childId, wrapperRef);
    });
}

export function removeAutoLayout(editor: Editor, wrapperRef: React.RefObject<HTMLDivElement>, objectIds: string[]) {
    objectIds.forEach(objId => {
        modifyLayout(editor, objId, wrapperRef, (layout, pos) => {
            delete layout.flexLayout;
        });
    });
    const allChildren = objectIds.flatMap(objId => {
        const obj = editor.state.value.document.objects[objId];
        if (!obj) { return []; }
        return childrenOf(obj);
    });
    allChildren.forEach(childId => {
        setLayoutMode(editor, 'absolute', childId, wrapperRef);
    });
}

interface AutoLayoutSectionProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    objects: BaseObject[]
}

function AutoLayoutSection(props: AutoLayoutSectionProps) {
    const {wrapperRef, objects} = props;
    const sharedFlexLayout = ifAllEqual(objects.map(obj => layoutOf(obj)?.flexLayout));
    const editor = useEditor();
    const gravity = sharedFlexLayout ? flexToGravity(sharedFlexLayout) : undefined;
    const [gap, setGap] = useState(sharedFlexLayout?.gap || 0);

    function updateGravity(newGravity: Gravity) {
        objects.forEach(obj => {
            modifyLayout(editor, obj.id, wrapperRef, (layout, pos) => {
                if (layout.flexLayout) {
                    updateFlexGravity(layout.flexLayout, newGravity);
                }
            });
        });
    }

    function updateGap(newGap: number) {
        objects.forEach(obj => {
            modifyLayout(editor, obj.id, wrapperRef, (layout, pos) => {
                if (layout.flexLayout) {
                    layout.flexLayout.gap = newGap;
                }
            });
        });
        setGap(newGap);
    }

    function updateFlexDirection(newDirection: 'row' | 'column') {
        objects.forEach(obj => {
            modifyLayout(editor, obj.id, wrapperRef, (layout, pos) => {
                if (layout.flexLayout) {
                    layout.flexLayout.direction = newDirection;
                }
            });
        });
    }

    return (
        <SidebarSection>
            <h6>Auto Layout</h6>
            <TwoColumnPropGroup left={(
                <GravityView gravity={gravity || {x: 'start', y: 'start'}} onChange={updateGravity} />
            )} right={(
                <>
                    <FlexDirectionPicker direction={sharedFlexLayout?.direction || 'column'} onChange={updateFlexDirection} />
                    <NumberInput label="Gap" value={gap} onChange={updateGap} />
                </>
            )} />
            <PropButton label="Remove Auto Layout" onClick={() => removeAutoLayout(editor, wrapperRef, objects.map(obj => obj.id))} />
        </SidebarSection>
    )
}

function FlexDirectionPicker({direction, onChange}: {direction: 'row' | 'column', onChange: (newDirection: 'row' | 'column') => void}) {
    function iconForDirection(d: 'row' | 'column') {
        return d === 'row' ? <TbArrowRight /> : <TbArrowDown />;
    }
    return (
        <SidebarSegmentedControl 
            options={['row', 'column']} 
            selection={direction} 
            viewForOption={d => iconForDirection(d)} 
            onChange={onChange} />
    )
}

interface Gravity {
    x: 'start' | 'center' | 'end';
    y: 'start' | 'center' | 'end';
}

function flexToGravity(flexLayout: FlexLayout): Gravity {
    if (flexLayout.direction === 'row') {
        return {
            x: flexLayout.justifyContent === 'flex-start' ? 'start' : flexLayout.justifyContent === 'center' ? 'center' : 'end',
            y: flexLayout.alignItems === 'flex-start' ? 'start' : flexLayout.alignItems === 'center' ? 'center' : 'end'
        }
    } else {
        // column
        return {
            x: flexLayout.alignItems === 'flex-start' ? 'start' : flexLayout.alignItems === 'center' ? 'center' : 'end',
            y: flexLayout.justifyContent === 'flex-start' ? 'start' : flexLayout.justifyContent === 'center' ? 'center' : 'end'
        }
    }
}

function updateFlexGravity(flexLayout: FlexLayout, gravity: Gravity) {
    if (flexLayout.direction === 'row') {
        flexLayout.justifyContent = gravity.x === 'start' ? 'flex-start' : gravity.x === 'center' ? 'center' : 'flex-end';
        flexLayout.alignItems = gravity.y === 'start' ? 'flex-start' : gravity.y === 'center' ? 'center' : 'flex-end';
    } else {
        flexLayout.alignItems = gravity.x === 'start' ? 'flex-start' : gravity.x === 'center' ? 'center' : 'flex-end';
        flexLayout.justifyContent = gravity.y === 'start' ? 'flex-start' : gravity.y === 'center' ? 'center' : 'flex-end';
    }
}

interface GravityViewProps {
    gravity: Gravity;
    onChange: (newGravity: Gravity) => void;
}

// The gravity view is a grid of 9 cells representing the 9 possible gravity values
const GravityCell = styled.div<{selected: boolean}>`
    width: 16px;
    height: 16px;
    background: ${props => props.selected ? '#ddd' : 'transparent'};
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: #eee;
    }
`;

/* 3x3 grid of gravity cells; shrink to fit */
const GravityViewContainer = styled.div`
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: fit-content;
    height: fit-content;
`;

function GravityView(props: GravityViewProps) {
    const {gravity, onChange} = props;
    const cells: (Gravity & {id: string})[] = [
        {x: 'start', y: 'start', id: 'top-left'},
        {x: 'center', y: 'start', id: 'top-center'},
        {x: 'end', y: 'start', id: 'top-right'},
        {x: 'start', y: 'center', id: 'middle-left'},
        {x: 'center', y: 'center', id: 'middle-center'},
        {x: 'end', y: 'center', id: 'middle-right'},
        {x: 'start', y: 'end', id: 'bottom-left'},
        {x: 'center', y: 'end', id: 'bottom-center'},
        {x: 'end', y: 'end', id: 'bottom-right'},
    ];
    return (
        <GravityViewContainer>
            {cells.map(cell => (
                <GravityCell key={cell.id} selected={cell.x === gravity.x && cell.y === gravity.y} onClick={() => onChange(cell)} />
            ))}
        </GravityViewContainer>
    )
}
