import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { CanvasRect, ClientRect, InfiniteCanvasPosition, Point, Rect, ViewportRect, ZeroRect, boundingRectForAllSelectors, rectFromClient, zeroRect } from "../data/geo";
import { RESIZE_HANDLES, ResizeHandleView } from "./selectionFrameHandles";
import equal from 'fast-deep-equal/es6';

interface SelectionFrameProps {
    objectIds: string[];
    wrapperRef: React.RefObject<HTMLDivElement>;
    infiniteCanvasPosition: InfiniteCanvasPosition; // We pass this to force redraw when the canvas position changes
}

// Use a layout effect to search the wrapper DOM for the selected object and set this view's position to it
export default function SelectionFrame(props: SelectionFrameProps) {
    const ref = useRef<HTMLDivElement>(null);
    const rect = useSelectorBboxResizeObserver(props.objectIds.map(id => `[data-object-id="${id}"]`).join(','), props.wrapperRef);

    return (
        <div className="selectionFrame" ref={ref} style={{ border: '2px solid #00b9ff', boxSizing: 'border-box', position: 'absolute', left: rect.x, top: rect.y, width: rect.width, height: rect.height, pointerEvents: 'none' }}>
            { RESIZE_HANDLES.map((handle) => <ResizeHandleView handle={handle} key={handle.id} />) }
        </div>
    );
}

const ZERO_CLIENT_RECT: ClientRect = { x: 0, y: 0, width: 0, height: 0, space: 'client' };
const ZERO_VIEWPORT_RECT: ViewportRect = { x: 0, y: 0, width: 0, height: 0, space: 'viewport' };

function useSelectorBboxResizeObserver(selector: string, rootRef: React.RefObject<HTMLDivElement>): ViewportRect {
    // Like useMultipleResizeObservers, but computes the bounding box. The elements are fetched in the layout effect
    const elementBoundingBoxes = useCallback(() => {
        const wrapper = rootRef.current;
        if (!wrapper) return ZERO_VIEWPORT_RECT;
        const wrapperRect = wrapper.getBoundingClientRect();
        const boundingRect = boundingRectForAllSelectors(wrapper, [selector], -wrapperRect.left, -wrapperRect.top) as any as ViewportRect;
        return boundingRect || ZERO_VIEWPORT_RECT;
    }, [selector, rootRef]);

    const [rect, setRect] = useState(elementBoundingBoxes);

    const setRectWithEqualityCheck = useCallback((newRect: ViewportRect) => {
        setRect(old => {
            if (equal(old, newRect)) {
                return old;
            }
            return newRect;
        });
    }, [rect]);

    useLayoutEffect(() => {
        // First, update rect
        setRectWithEqualityCheck(elementBoundingBoxes());

        if (!rootRef.current) return;

        // THen, create observers
        const resizeObservers = Array.from(rootRef.current.querySelectorAll(selector)).map((element) => {
            const observer = new ResizeObserver(() => {
                setRectWithEqualityCheck(elementBoundingBoxes());
            });
            observer.observe(element);
            return observer;
        });

        return () => {
            resizeObservers.forEach(observer => observer.disconnect());
        };
    });

    return rect;
}



// function useMultipleResizeObservers(elements: HTMLElement[]): ClientRect[] {
//     const initialRects = elements.map(element => rectFromClient(element.getBoundingClientRect()));
//     const [rects, setRects] = useState<ClientRect[]>(initialRects);

//     const setRectsWithEqualityCheck = useCallback((newRects: ClientRect[]) => {
//         setRects(old => {
//             if (equal(old, newRects)) {
//                 return old;
//             }
//             return newRects;
//         });
//     }, [rects]);

//     useLayoutEffect(() => {
//         function refreshPositions() {
//             // Read cur positions
//             const curRects = elements.map(element => rectFromClient(element.getBoundingClientRect()));
//             setRectsWithEqualityCheck(curRects);
//         }

//         // Create observers
//         const observers = elements.map((element, index) => {
//             const observer = new ResizeObserver(entries => {
//                 refreshPositions();
//             });
//             observer.observe(element);
//             return observer;
//         });

//         return () => {
//             observers.forEach(observer => observer.disconnect());
//         }
//     });

//     return rects;
// }

// function useResizeObserver(element: HTMLElement | null): ClientRect {
//     const curRect: ClientRect = element ? rectFromClient(element.getBoundingClientRect()) : {...ZeroRect, space: 'client'};
//     const [rect, setRect] = useState<ClientRect>(curRect);

//     useLayoutEffect(() => {
//         if (!element) return;
//         const observer = new ResizeObserver(entries => {
//             const newRect = entries[0].contentRect;
//             setRect(rectFromClient(newRect));
//         });
//         observer.observe(element);
//         return () => observer.disconnect();
//     }, [element]);

//     return rect;
// }
