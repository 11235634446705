import { CanvasPoint } from "./geo";

export interface Document {
    id: string;
    objects: { [id: string]: BaseObject };
    rootIds: string[];
    name: string;
}

export interface BaseObject {
    id: string
    type: string
    parent?: string
    hidden?: boolean
    name?: string // user-visible
    userCSS?: React.CSSProperties
}

export interface EditableFrame extends BaseObject {
    type: 'editableFrame'
    layout: LayoutProps
    stylingProps: StylingProps
    children: string[] // IDs of objects
    isArtboard?: boolean

    // "Make Real"
    behaviorDescription?: string;
    code?: string;
    displayAsCode?: boolean;
}

export const DEFAULT_TEXT = "Your text here"
export interface EditableText extends BaseObject {
    type: 'editableText'
    layout: LayoutProps
    text: string
    alignment: 'left' | 'center' | 'right'
    color?: Color;
    font?: Font;
}

export interface EditableTextField extends BaseObject {
    type: 'editableTextField'
    layout: LayoutProps
    placeholder: string
    value: string
    alignment?: 'left' | 'center' | 'right'
    font?: Font;
    color?: Color;
}

export interface LayoutProps {
    position?: { kind: 'absolute', x: Positioning, y: Positioning } | { kind: 'inline' };
    xSize?: Sizing
    ySize?: Sizing
    flexLayout?: FlexLayout
    padding?: Padding
}

export interface HTMLEmbed extends BaseObject {
    type: 'htmlEmbed'
    html: string
    layout: LayoutProps
    prompt?: string
    params: ObjectParam[]
}

export type Sizing = { kind: 'hug' } | { kind: 'fixed', value: number, unit: 'pixels' | 'percent' } | { kind: 'trailing', offset: number, unit: 'pixels' | 'percent' };
export type Positioning = { value: number, unit: 'pixels' | 'percent', anchor: 'leading' | 'center' | 'trailing' };
export type FlexLayout = { direction: 'row' | 'column', justifyContent: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around', alignItems: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline', gap?: number };
export type Padding = { top: number, right: number, bottom: number, left: number }

export function paddingAll(value: number): Padding {
    return { top: value, right: value, bottom: value, left: value }
}

export interface StylingProps {
    background?: Fill
    clip?: boolean;
    opacity?: number;
    cornerRadius?: number;
    shadow?: Shadow
}

export type Fill = { color: Color };
export type Color = { r: number, g: number, b: number, a: number }; // r: 0..255, g: 0..255, b: 0..255, a: 0..1
export type Shadow = { x: number, y: number, blur: number, color: Color };

export interface Font {
    size?: number
    weight?: number
    family?: string
}

export interface ParamKind {
    kind: 'string' | 'number' | 'boolean' | 'color' | 'signal' | 'dict'
    range?: { min: number, max: number } // for kind: 'number'
    signalValue?: ParamKind
    fields?: { [key: string]: ParamKind }
}

export interface ObjectParam {
    id: string
    name: string
    kind: ParamKind
    value?: ParamValue; // Signals do not have values
}

export type ParamValue = string | number | boolean | Color | { [key: string]: ObjectParam['value'] };

// MARK: - Helpers

export function childrenOf(object: BaseObject): string[] {
    if (object.type === 'editableFrame') {
        const editableFrame = object as EditableFrame;
        return editableFrame.children || [];
    }
    return [];
}

export function supportsChildren(object: BaseObject): boolean {
    return object.type === 'editableFrame';
}

export function layoutOf(object: BaseObject): LayoutProps | null {
    if (object.type === 'editableFrame') {
        const editableFrame = object as EditableFrame;
        return editableFrame.layout;
    }
    if (object.type === 'editableText') {
        return (object as EditableText).layout;
    }
    if (object.type === 'editableTextField') {
        return (object as EditableTextField).layout;
    }
    if (object.type === 'htmlEmbed') {
        return (object as HTMLEmbed).layout;
    }
    return null;
}

export function flexLayoutOf(object: BaseObject): FlexLayout | null {
    const layout = layoutOf(object);
    return layout && layout.flexLayout ? layout.flexLayout : null;
}

export function stylingOf(object: BaseObject): StylingProps | null {
    if (object.type === 'editableFrame') {
        const editableFrame = object as EditableFrame;
        return editableFrame.stylingProps;
    }
    return null;
}

export function paramsOf(object: BaseObject): ObjectParam[] | null {
    if (object.type === 'htmlEmbed') {
        return (object as HTMLEmbed).params;
    }
    return null;
}

// MARK: - Conversion to CSS

export function cssForObject(obj: BaseObject): React.CSSProperties {
    let dict: React.CSSProperties = {};
    if (obj.type === 'editableFrame') {
        const editableFrame = obj as EditableFrame;
        dict = { ...dict, ...stylingToCSS(editableFrame.stylingProps) }
    }
    const layout = layoutOf(obj);
    if (layout) {
        dict = { ...dict, ...layoutToCSS(layout) }
    }
    if (obj.type === 'editableText') {
        const editableText = obj as EditableText;
        dict = { ...dict, textAlign: editableText.alignment };
        if (editableText.color) {
            dict = { ...dict, color: `rgba(${editableText.color.r}, ${editableText.color.g}, ${editableText.color.b}, ${editableText.color.a})` }
        }
        if (editableText.font) {
            dict = { ...dict, ...fontToCSS(editableText.font) }
        }
    }
    if (obj.type === 'editableTextField') {
        const editableTextField = obj as EditableTextField;
        if (editableTextField.font) {
            dict = { ...dict, ...fontToCSS(editableTextField.font) }
        }
        // if (editableTextField.padding) {
        //     dict = { ...dict, ...paddingToCSS(editableTextField.padding) }
        // }
        if (editableTextField.alignment) {
            dict = { ...dict, textAlign: editableTextField.alignment }
        }
    }
    if (obj.type === 'htmlEmbed') {
        const htmlEmbed = obj as HTMLEmbed;
        if (htmlEmbed.layout) {
            dict = { ...dict, ...layoutToCSS(htmlEmbed.layout) }
        }
    }
    if (obj.hidden) {
        dict = { ...dict, display: 'none' };
    }
    if (obj.userCSS) {
        dict = { ...dict, ...obj.userCSS }
    }
    return dict;
}

function layoutToCSS(layout: LayoutProps): React.CSSProperties {
    let dict: React.CSSProperties = {};
    if (layout.xSize) {
        dict = { ...dict, ...sizeToCSS(layout.xSize, 'x') }
    }
    if (layout.ySize) {
        dict = { ...dict, ...sizeToCSS(layout.ySize, 'y') }
    }
    if (layoutNeedsWrapper(layout)) {
        dict.position = 'relative';
        dict.pointerEvents = 'all'; // Because the wrapper has pointer events turned off
    } else {
        // If either position axis is fixed, we need to set the position to absolute
        if (layout.position && layout.position.kind === 'absolute') {
            dict.position = 'absolute';
        } else {
            dict.position = 'relative';
        }
    }
    if (layout.position && layout.position.kind === 'absolute') {
        dict = { ...dict, ...positionToCSS(layout.position.x, 'x'), ...positionToCSS(layout.position.y, 'y') }
    }
    if (layout.flexLayout) {
        dict = { ...dict, ...flexLayoutToCSS(layout.flexLayout) }
    }
    if (layout.padding) {
        dict = { ...dict, ...paddingToCSS(layout.padding) }
    }
    return dict;
}

export function layoutNeedsWrapper(layout: LayoutProps | null): boolean {
    if (!layout) return false;
    if (layout.position && layout.position.kind === 'absolute') {
        if (layout.position.x.anchor === 'center' || layout.position.y.anchor === 'center') {
            return true;
        }
    }
    return false;
}

export function wrapperCSS(layout: LayoutProps | null): React.CSSProperties {
    if (!layout) return {};
    const dict: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none'
    };

    function anchorToFlex(anchor: 'leading' | 'center' | 'trailing'): 'flex-start' | 'center' | 'flex-end' {
        switch (anchor) {
            case 'leading':
                return 'flex-start';
            case 'center':
                return 'center';
            case 'trailing':
                return 'flex-end';
        }
    }

    if (layout.position && layout.position.kind === 'absolute') {
        dict.alignItems = anchorToFlex(layout.position.x.anchor);
        dict.justifyContent = anchorToFlex(layout.position.y.anchor);
        // switch (layout.position.x.anchor) {
        //     case 'leading':
        //         dict.alignItems = 'flex-start';
        //         break;
        //     case 'center':
        //         dict.alignItems = 'center';
        //         break;
        //     case 'trailing':
        //         dict.alignItems = 'flex-end';
        //         break;
        // }
        // switch (layout.position.y.anchor) {
        //     case 'leading':
        //         dict.justifyContent = 'flex-start';
        //         break;
        //     case 'center':
        //         dict.justifyContent = 'center';
        //         break;
        //     case 'trailing':
        //         dict.justifyContent = 'flex-end';
        //         break;
        // }
    }
    return dict;
}

function stylingToCSS(styling: StylingProps): React.CSSProperties {
    let dict: React.CSSProperties = {};
    if (styling.background) {
        dict = { ...dict, ...fillToCSS(styling.background) }
    }
    if (styling.clip) {
        dict = {...dict, overflow: 'hidden'}
    }
    if (styling.opacity) {
        dict = {...dict, opacity: styling.opacity}
    }
    if (styling.cornerRadius) {
        dict = {...dict, borderRadius: styling.cornerRadius}
    }
    if (styling.shadow) {
        const shadow = styling.shadow;
        dict = {
            ...dict,
            boxShadow: `${shadow.x}px ${shadow.y}px ${shadow.blur}px rgba(${shadow.color.r}, ${shadow.color.g}, ${shadow.color.b}, ${shadow.color.a})`
        }
    }
    return dict;
}

function fillToCSS(fill: Fill): React.CSSProperties {
    return { backgroundColor: `rgba(${fill.color.r}, ${fill.color.g}, ${fill.color.b}, ${fill.color.a})` }
}

function fontToCSS(font: Font): React.CSSProperties {
    return { fontSize: font.size, fontFamily: font.family, fontWeight: font.weight }
}

function flexLayoutToCSS(flexLayout: FlexLayout): React.CSSProperties {
    return {
        display: 'flex',
        flexDirection: flexLayout.direction,
        justifyContent: flexLayout.justifyContent,
        alignItems: flexLayout.alignItems,
        gap: flexLayout.gap
    }
}

function paddingToCSS(padding: Padding): React.CSSProperties {
    // if all props are same, use shorthand
    if (padding.top === padding.right && padding.right === padding.bottom && padding.bottom === padding.left) {
        return padding.top === 0 ? {} : { padding: padding.top };
    }
    return { paddingTop: padding.top, paddingRight: padding.right, paddingBottom: padding.bottom, paddingLeft: padding.left }
}

function sizeToCSS(size: Sizing, axis: 'x' | 'y'): React.CSSProperties {
    switch (size.kind) {
        case 'hug':
            return {};
        case 'fixed':
            return { [axis === 'x' ? 'width' : 'height']: valueAndUnit(size) }
        case 'trailing':
            return { [axis === 'x' ? 'right' : 'bottom']: valueAndUnit({value: size.offset, unit: size.unit}) };
    }
    // if ('fill' in size) {
    //     // TODO: use flex or align self (need to pass parent layout tho)
    //     return { [axis === 'x' ? 'width' : 'height']: '100%' }
    // } else if ('fixed' in size) {
    //     const unit = size.fixed === 'percent' ? '%' : 'px';
    //     return { [axis === 'x' ? 'width' : 'height']: `${size.value}${unit}` }
    // } else {
    //     return { [axis === 'x' ? 'width' : 'height']: 'fit-content' }
    // }
    // return {};
}

function valueAndUnit(layout: {value: number, unit: 'pixels' | 'percent'}): string {
    return `${layout.value}${layout.unit === 'pixels' ? 'px' : '%'}`;
}

function positionToCSS(position: Positioning, axis: 'x' | 'y'): React.CSSProperties {
    const valAndUnit = valueAndUnit(position);
    switch (position.anchor) {
        case 'leading':
            return { [axis === 'x' ? 'left' : 'top']: valAndUnit };
        case 'trailing':
            return { [axis === 'x' ? 'right' : 'bottom']: valAndUnit };
        case 'center':
            return { [axis === 'x' ? 'left' : 'top']: valAndUnit }; // We'll be wrapped in a centered flex container, so just need to handle the offset
    }
    return {};
}

// HELPERS
export function getFixedLeadingPosition(layout: LayoutProps): CanvasPoint | null {
    if (layout.position && layout.position.kind === 'absolute') {
        return {
            x: layout.position.x.value,
            y: layout.position.y.value,
            space: 'canvas'
        }
    }
    return null;
}

export function isAbsolutePosition(obj: BaseObject): boolean {
    const layout = layoutOf(obj);
    if (!layout) return false;
    return !!(layout.position && layout.position.kind === 'absolute');
}

export function isArtboard(obj: BaseObject): boolean {
    return obj.type === 'editableFrame' && !obj.parent && !!((obj as EditableFrame).isArtboard);
}
