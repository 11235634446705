import equal from "fast-deep-equal/es6";
import { copyToClipboard, paste } from "../data/clipboard";
import { Editor, EditorState, PenMode } from "../data/editor";
import { Document, LayoutProps, Positioning, childrenOf, isAbsolutePosition, layoutOf } from "../data/model";
import { deleteObjects, getChildrenArray, locationOfObject, nearestCommonParent, sortObjectsByRenderOrder } from "../data/operations";
import { ViewportPoint } from "../data/geo";
import { addAutoLayout } from "./sidebar/layout";

export function handleKeys(editor: Editor, e: KeyboardEvent): boolean {
    // Handle CMD+0
    if (e.metaKey && e.key === '0') {
        editor.modify(state => {
            state.canvasPos.zoom = 1;
        });
        return true;
    }

    const textFieldOrContentEditable = !!(e.target instanceof HTMLInputElement || document.querySelector('[contenteditable="true"]:focus') || e.target instanceof HTMLTextAreaElement);

    if (!textFieldOrContentEditable) {
        // Handle CMD+A
        if (e.metaKey && e.key === 'a') {
            selectAll(editor)
            return true;
        }
        // // Handle Shift+A
        // if (e.shiftKey && e.key === 'a') {
        //     // export function addAutoLayout(editor: Editor, wrapperRef: React.RefObject<HTMLDivElement>, objectIds: string[]) {
        //     if (editor.viewportRef?.current) {
        //         addAutoLayout(editor, editor.viewportRef, Array.from(editor.state.value.selectedObjects));
        //     }
        //     return true;
        // }

            // Key actions that we handle despite having a text input focused:
        if (e.key === 'z' && e.metaKey) {
            if (e.shiftKey) {
                editor.redo();
            } else {
                editor.undo();
            }
            return true;
        }

        // Handle copy/paste
        if (e.metaKey && e.key === 'c') {
            copyToClipboard(editor, false);
            return true;
        }
        if (e.metaKey && e.key === 'x') {
            copyToClipboard(editor, true);
            return true;
        }
        if (e.metaKey && e.key === 'v') {
            paste(editor);
            return true;
        }

        if (!e.metaKey) {
            // Raw keys
            if (e.key === 'r') {
                togglePenMode(editor, PenMode.RECTANGLE);
            }
            if (e.key === 'f' || e.key === 'a') {
                togglePenMode(editor, PenMode.FRAME);
            }
            if (e.key === 't') {
                togglePenMode(editor, PenMode.TEXT);
            }
            if (e.key === 'o') {
                togglePenMode(editor, PenMode.OVAL);
            }
            if (e.key === 'c') {
                togglePenMode(editor, PenMode.HTML);
            }
            if (e.key === 'i') {
                togglePenMode(editor, PenMode.INPUT_FIELD);
            }
            if (e.key === 'Escape') {
                // TODO
                if (editor.state.value.penMode) {
                    togglePenMode(editor, undefined);
                }
            }
        }
    
        // Shift-enter to select parents
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                selectParents(editor);
            } else {
                selectChildren(editor);
            }
            return true;
        }

        // Arrow to nudge
        const nudgeAmt = e.shiftKey ? 10 : 1;
        if (e.key === 'ArrowUp') {
            nudge({ x: 0, y: -nudgeAmt, space: 'viewport' }, editor);
            return true;
        }
        if (e.key === 'ArrowDown') {
            nudge({ x: 0, y: nudgeAmt, space: 'viewport' }, editor);
            return true;
        }
        if (e.key === 'ArrowLeft') {
            nudge({ x: -nudgeAmt, y: 0, space: 'viewport' }, editor);
            return true;
        }
        if (e.key === 'ArrowRight') {
            nudge({ x: nudgeAmt, y: 0, space: 'viewport' }, editor);
            return true;
        }
    
        // Handle delete
        if (e.key === 'Backspace' || e.key === 'Delete') {
            editor.modifyUndoable(state => {
                return deleteObjects(state, Array.from(state.selectedObjects));
            });
            return true;
        }
    
        // Handle [ and ] to move things to back and front
        if (e.key === '[') {
            reorder('back', editor);
            return true;
        }
    
        if (e.key === ']') {
            reorder('front', editor);
            return true;
        }    
    }

    return false;
}

function togglePenMode(editor: Editor, mode: PenMode | undefined) {
    editor.modifyUndoable(state => {
        const old = state.penMode;
        const newMode = state.penMode === mode ? undefined : mode;
        return {
            do: (state) => state.penMode = newMode,
            undo: (state) => state.penMode = old
        }
    });
}

function reorder(pos: 'back' | 'front', editor: Editor) {
    const originalIndices: { [id: string]: number } = {};
    const ids = Array.from(editor.state.value.selectedObjects);
    // Populate original indices
    for (const obj of ids) {
        const loc = locationOfObject(editor.state.value.document, obj);
        if (loc) {
            originalIndices[obj] = loc.index;
        }
    }
    // Sort by original index (asc)
    ids.sort((a, b) => originalIndices[a] - originalIndices[b]);
    if (pos === 'back') {
        ids.reverse();
    }
    for (const objId of ids) {
        _reorder(objId, pos, editor);
    }
}

function _reorder(id: string, pos: 'back' | 'front', editor: Editor) {
    const origIndex = locationOfObject(editor.state.value.document, id)?.index;
    if (origIndex === undefined) return;
    editor.modifyUndoable(state => {
        return {
            do: state => {
                const siblingArray = getChildrenArray(state.document.objects[id].parent || null, state.document);
                if (siblingArray) {
                    moveItemToPositionInArray(siblingArray, id, pos);
                }
            },
            undo: state => {
                const siblingArray = getChildrenArray(state.document.objects[id].parent || null, state.document);
                if (siblingArray) {
                    setItemIndexInArray(siblingArray, id, origIndex);
                }
            }
        }
    });
}


function setItemIndexInArray<T>(items: T[], item: T, index: number) {
    const currentIndex = items.indexOf(item);
    if (currentIndex > -1) {
        items.splice(currentIndex, 1);
    }
    items.splice(Math.min(items.length, index), 0, item);
}

function moveItemToPositionInArray<T>(items: T[], item: T, position: 'front' | 'back') {
    // back is 0, front is length - 1
    // First, remove item
    const index = items.indexOf(item);
    if (index > -1) items.splice(index, 1);
    // Then, add it back
    switch (position) {
        case 'back':
            items.unshift(item);
            break;
        case 'front':
            items.push(item);
            break;
    }
}

export function modifySelectionHandlingUndo(editor: Editor, modify: (selection: Set<string>, doc: Document) => void) {
    editor.modifyUndoable(state => {
        const originalSelection = new Set(state.selectedObjects);
        return {
            do: state => modify(state.selectedObjects, state.document),
            undo: state => state.selectedObjects = originalSelection
        }
    });
}

function selectAll(editor: Editor) {
    modifySelectionHandlingUndo(editor, (selection, doc) => {
        const containerToSelectWithin = nearestCommonParent(doc, Array.from(selection));
        const itemsToSelect = getChildrenArray(containerToSelectWithin, doc);
        if (itemsToSelect === null) return;

        selection.clear();
        itemsToSelect.forEach(id => selection.add(id));
    });
}

function selectChildren(editor: Editor) {
    modifySelectionHandlingUndo(editor, (selection, doc) => {
        const newSelection = new Set<string>();
        selection.forEach(id => {
            const children = getChildrenArray(id, doc);
            if (children) {
                children.forEach(child => newSelection.add(child));
            }
        });
        if (newSelection.size === 0) {
            return;
        }
        selection.clear();
        newSelection.forEach(id => selection.add(id));
    });
}

function selectParents(editor: Editor) {
    modifySelectionHandlingUndo(editor, (selection, doc) => {
        const newSelection = new Set<string>();
        selection.forEach(id => {
            const obj = doc.objects[id];
            if (obj && obj.parent) {
                newSelection.add(obj.parent);
            }
        });
        selection.clear();
        console.log('parents', newSelection);
        newSelection.forEach(id => selection.add(id));
    });
}

function nudge(delta: ViewportPoint, editor: Editor) {
    const scale = editor.state.value.canvasPos.zoom;
    delta = { x: delta.x / scale, y: delta.y / scale, space: 'viewport' };
    const selectedIds = Array.from(editor.state.value.selectedObjects);
    sortObjectsByRenderOrder(selectedIds, editor.state.value.document);
    const gestureId = `nudge-${selectedIds.join('__')}`;

    function nudgePosition(pos: Positioning | undefined, delta: number) {
        if (!pos) return;
        // TODO: Handle percents
        switch (pos.anchor) {
            case 'leading':
                pos.value += delta;
                break;
                case 'center':
                pos.value += delta;
                break;
            case 'trailing':
                pos.value -= delta;
                break;
        }
    }

    function nudgeObject(id: string, delta: ViewportPoint, state: EditorState) {
        const obj = state.document.objects[id];
        if (!obj) return;
        if (isAbsolutePosition(obj)) {
            const pos = layoutOf(obj)?.position;
            if (!pos || pos.kind !== 'absolute') return;    
            nudgePosition(pos.x, delta.x);
            nudgePosition(pos.y, delta.y);
        } else {
            // Move index forward/backwards
            // TODO
        }
    }

    // TODO: Fix undo
    editor.modifyUndoable(state => {
        return {
            gestureId,
            do: state => {
                for (const obj of selectedIds) {
                    nudgeObject(obj, delta, state);
                }
            },
            undo: state => {
                const reverseDelta: ViewportPoint = { x: -delta.x, y: -delta.y, space: 'viewport' };
                for (const obj of selectedIds) {
                    nudgeObject(obj, reverseDelta, state);
                }
            }
        }
    });
}
