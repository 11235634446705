
export function compactMap<T, U>(array: T[], mapper: (item: T) => U | undefined): U[] {
    const result: U[] = [];
    for (const item of array) {
        const mapped = mapper(item);
        if (mapped !== undefined) {
            result.push(mapped);
        }
    }
    return result;
}
