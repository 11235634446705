import { Editor } from "./editor";
import { BaseObject, EditableText, childrenOf, isAbsolutePosition, layoutOf, supportsChildren } from "./model";
import { addObject, deleteObjects, descendantIds, getChildrenArray, nearestCommonParent, sortObjectsByRenderOrder } from "./operations";
import { v4 as uuidv4 } from "uuid";
import { escapeHTML } from "../utils/html";
import clone from 'rfdc';
import equal from "fast-deep-equal/es6";

const copy = clone();

interface ClipboardPayload {
    kind: 'designtool-payload-v1',
    copiedObjectIds: string[];
    // Contains all selected objects and children
    relevantObjects: {[key: string]: BaseObject};
}

export function copyToClipboard(editor: Editor, cut: boolean) {
    const editorState = editor.state.value;
    if (editorState.selectedObjects.size === 0) {
        return;
    }
    // Construct payload and copy it
    const payload: ClipboardPayload = {
        kind: 'designtool-payload-v1',
        copiedObjectIds: Array.from(editorState.selectedObjects),
        relevantObjects: {},
    };
    sortObjectsByRenderOrder(payload.copiedObjectIds, editorState.document);
    for (const id of payload.copiedObjectIds) {
        const object = copy(editorState.document.objects[id]);
        object.parent = undefined;
        payload.relevantObjects[id] = object;

        // Copy all descendants too
        descendantIds(editorState.document, id).forEach(descendantId => {
            payload.relevantObjects[descendantId] = copy(editorState.document.objects[descendantId]);
        });
    }
    // Use async clipboard api to write data
    // TODO: Define our own format
    const clipboardItem = new ClipboardItem({
        "web application/json": new Blob([JSON.stringify(payload)], {type: "application/json"}),
    });
    navigator.clipboard.write([clipboardItem]);

    // If cut, delete the objects
    if (cut) {
        editor.modifyUndoable(state => deleteObjects(state, payload.copiedObjectIds));
    }
}

export async function paste(editor: Editor) {
    const editorState = editor.state.value;
    const payload = await readPayloadFromClipboard();
    if (payload === null) {
        return;
    }
    // Normally we paste objects into the selected object. 
    // If there is a multi-selection, we paste into the nearest common parent.
    // If there is no selection, we paste into the root.
    // If the selection does not support children, we paste into the parent of the selection.
    // If the selection IS the originally copied object, we paste into the parent of the selection. (duplicate as sibling)
    let parentId: string | null;
    let offsetPos = false;

    // TODO: When pasting siblings, preserve position in list
    // let insertionIndex: number | null = null;

    if (editorState.selectedObjects.size === 1) {
        parentId = editorState.selectedObjects.values().next().value;
        const parentObj = editorState.document.objects[parentId!];
        // If we're pasting a single object and it's the same as the copied object, paste as sibling
        if (payload.copiedObjectIds.length === 1 && objectsAreEqualSansIdParentAndPos(parentObj, payload.relevantObjects[payload.copiedObjectIds[0]])) {
            parentId = parentObj.parent || null;
            offsetPos = true;
            // insertionIndex = getChildrenArray(parentId, editorState.document)?.indexOf(parentId!);
        } else if (!supportsChildren(parentObj)) {
            // If the parent does not support children, paste into the parent
            parentId = parentObj.parent || null;
        }
    } else if (editorState.selectedObjects.size > 1) {
        parentId = nearestCommonParent(editorState.document, Array.from(editorState.selectedObjects));
    } else {
        // No selection
        parentId = null;
    }

    // TODO: When pasting into root, do it at the center of the viewport

    const payloadWithNewIds = copy(payload);
    assignNewIds(payloadWithNewIds);
    // If pasting into auto layout parent, apply auto layout, else fixed
    const parentHasAutoLayout = parentId && layoutOf(editorState.document.objects[parentId])?.flexLayout !== undefined;
    for (const id of payloadWithNewIds.copiedObjectIds) {
        const obj = payloadWithNewIds.relevantObjects[id];
        const layout = layoutOf(obj);
        const hasInlineLayout = layout && layout.position && layout.position.kind === 'inline';
        if (layout && hasInlineLayout !== parentHasAutoLayout) {
            layout.position = parentHasAutoLayout ? { kind: 'inline' } : { kind: 'absolute', x: { value: 0, unit: 'pixels', anchor: 'leading' }, y: { value: 0, unit: 'pixels', anchor: 'leading' } };
        }
    }
    // TODO: Prevent pasting into parent outside of parent bounds
    // Apply offsets
    updatePositionsForNewParent(payloadWithNewIds, offsetPos ? 10 * editorState.canvasPos.zoom : 0);

    editor.modifyUndoable(state => {
        const oldSelectedObjects = new Set(state.selectedObjects);
        return {
            do: state => {
                // Add all objects to the document
                for (const obj of Object.values(payloadWithNewIds.relevantObjects)) {
                    state.document.objects[obj.id] = obj;
                }
                for (const id of payloadWithNewIds.copiedObjectIds) {
                    const obj = state.document.objects[id];
                    addObject(state.document, obj, {parent: parentId, index: -1});
                }
                state.selectedObjects = new Set(payloadWithNewIds.copiedObjectIds);
            },
            undo: state => {
                state.selectedObjects = oldSelectedObjects;
                deleteObjects(state, payloadWithNewIds.copiedObjectIds).do(state);
            }
        }
    })
}

function readPayloadFromClipboard(): Promise<ClipboardPayload | null> {
    return navigator.clipboard.read().then(data => {
        for (const item of data) {
            console.log(item.types);
            if (item.types.includes("web application/json")) {
                return item.getType("web application/json").then(blob => {
                    return tryDecodePayload(blob);
                });
            }
            // Check if clipboard includes text; paste if so
            if (item.types.includes("text/plain")) {
                return item.getType("text/plain").then(blob => {
                    return blob.text().then(text => {
                        return clipboardPayloadForPastingPlainText(text);
                    });
                });
            }
            // TODO: Support pasting images
        }
        return null;
    });
}

async function tryDecodePayload(blob: Blob): Promise<ClipboardPayload | null> {
    try {
        const text = await blob.text();
        const payload = JSON.parse(text);
        // Validate
        // TODO: Do better type validation
        if (!payload.copiedObjectIds || !payload.relevantObjects) {
            return null;
        }
        if (payload.kind !== 'designtool-payload-v1') {
            return null;
        }
        return payload;
    } catch (e) {
        console.error(e);
        return null;
    }
}

function clipboardPayloadForPastingPlainText(text: string): ClipboardPayload {
    const payload: ClipboardPayload = {
        copiedObjectIds: [],
        relevantObjects: {},
        kind: 'designtool-payload-v1',
    };
    const newObj: EditableText = {
        id: uuidv4(),
        type: "editableText",
        text: escapeHTML(text),
        layout: {
            position: { 
                kind: 'absolute',
                x: { value: 0, unit: 'pixels', anchor: 'leading' },
                y: { value: 0, unit: 'pixels', anchor: 'leading' },
            }
        },
        alignment: 'left',
    };
    payload.copiedObjectIds.push(newObj.id);
    payload.relevantObjects[newObj.id] = newObj;
    return payload;
}

function assignNewIds(payload: ClipboardPayload) {
    const idMap: {[key: string]: string} = {};
    for (const id of Object.keys(payload.relevantObjects)) {
        idMap[id] = uuidv4();
    }
    // iterate thru all relevant objects, update children and parent
    const newRelevantObjects: {[key: string]: BaseObject} = {};
    for (const obj of Object.values(payload.relevantObjects)) {
        obj.id = idMap[obj.id];
        if (obj.parent) {
            obj.parent = idMap[obj.parent!];
        }
        if (supportsChildren(obj)) {
            const children = childrenOf(obj);
            const newChildren = children.map(id => idMap[id]);
            children.splice(0, children.length, ...newChildren);
        }
        newRelevantObjects[obj.id] = obj;
    }
    payload.copiedObjectIds = payload.copiedObjectIds.map(id => idMap[id]);
    payload.relevantObjects = newRelevantObjects;
}

function updatePositionsForNewParent(payload: ClipboardPayload, offset: number) {
    for (const id of payload.copiedObjectIds) {
        const obj = payload.relevantObjects[id];
        if (!obj) { continue }
        if (isAbsolutePosition(obj)) {
            const layout = layoutOf(obj);
            // TOOD: Handle other anchor types
            if (layout && layout.position && layout.position.kind === 'absolute') {
                layout.position.x.value += offset;
                layout.position.y.value += offset;
            }
        }
    }
    // TODO: When pasting into root, make sure to make everything position: fixed
}

function objectsAreEqualSansIdParentAndPos(a: BaseObject, b: BaseObject): boolean {
    const bCopy = copy(b);
    bCopy.id = a.id;
    bCopy.parent = a.parent;
    const bLayout = layoutOf(bCopy);
    const aLayout = layoutOf(a);
    if (bLayout && aLayout) {
        bLayout.position = aLayout.position;
    }
    return equal(a, bCopy);
}
